import {NOTIFICATION_TYPES_BY_NAME} from '../../../services/UserNotificationService';
import {PCComponentModel} from "../../../models/pc-component-model";
import {BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";


const $inject = [
  "$scope",
  "$state",
  "$timeout",
  "Made",
  "vbmData",
  "componentsService",
  "cmsService",
  "employeeService",
  "ShopCategoryConstants",
  "SteuerklassenConstants",
  "potentialCalculatorService",
  "PopupService",
  "PopupVideoKeys",
  "dialogService",
  "CheckoutService",
  "NotificationService",
  "customerService",
  "UserNotificationService",
  "MenuConstants",
  "BonusService",
  "$q",
  "lodash",
  "authenticationService",
  "fileService",
  "ACLService",
  "SupportService",
  "NeoComponentsService",
  "VSP_CONSTANTS",
  "DigitalSigningService",
  "CarLeasingApiService",
  "AdministrationService",
  '$window',
  'MatDialogService',
  'mailService',
];

export default class DashboardArbeitnehmerController {

  constructor(
    $scope,
    $state,
    $timeout,
    Made,
    vbmData,
    componentsService,
    cmsService,
    employeeService,
    ShopCategoryConstants,
    SteuerklassenConstants,
    potentialCalculatorService,
    PopupService,
    PopupVideoKeys,
    dialogService,
    CheckoutService,
    NotificationService,
    customerService,
    UserNotificationService,
    MenuConstants,
    BonusService,
    $q,
    lodash,
    authenticationService,
    fileService,
    ACLService,
    SupportService,
    NeoComponentsService,
    VSP_CONSTANTS,
    DigitalSigningService,
    CarLeasingApiService,
    AdministrationService,
    $window,
    MatDialogService,
    mailService
  ) {

    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      Made,
      vbmData,
      componentsService,
      cmsService,
      employeeService,
      ShopCategoryConstants,
      SteuerklassenConstants,
      potentialCalculatorService,
      PopupService,
      dialogService,
      CheckoutService,
      NotificationService,
      customerService,
      UserNotificationService,
      MenuConstants,
      BonusService,
      $q,
      lodash,
      authenticationService,
      fileService,
      ACLService,
      SupportService,
      NeoComponentsService,
      VSP_CONSTANTS,
      DigitalSigningService,
      CarLeasingApiService,
      AdministrationService,
      $window,
      MatDialogService,
      mailService
    });

    this.BONUS_STATES_BY_NAME = BONUS_STATES_BY_NAME;
    this.$scope.MenuConstants = this.MenuConstants;
    this.styles = {};

    this.$scope.steuerklassen = this.SteuerklassenConstants;
    this.$scope.shopCategories = this.ShopCategoryConstants.carousel;

    this.loading = true;
    this.$scope.user = this.Made.user;

    this.$scope.showSteuerfreiExtrasComponents = false;

    this.$scope.clicker = {};

    $scope.formatDate = (date_str) => {
      if (date_str === undefined) {
        return ''
      }
      let dateToFormat = new Date(date_str);
      let formatted_date = dateToFormat.getDate() + '.' + (dateToFormat.getMonth() + 1) + '.' + dateToFormat.getFullYear();
      return formatted_date;
    };

    this.PopupService.processDashboardVideo(
      PopupVideoKeys.MODULE_DASHBOARD_AN,
      this.$scope.clicker
    );

    this.amIApprover();
    this.amIEmployer();

    let configurationPromise = this.customerService
      .getConfiguration()
      .then(configuration => {
        this.$scope.configuration = configuration;
        this.$scope.is_digital_signature_enabled = this.lodash.get(configuration, 'digital_signiture_config.is_enabled');
      });

    let neoProjectPromise = this.customerService.getNeoProjectByEmployeeId(this.Made.user.valuenet_id).then((neoProject) => {
      this.$scope.neoProject = neoProject;
    });

    this.vbmData
      .getData(this.Made.user.valuenet_id)
      .finally(() => {
        this.loading = false
        this.$scope.employee = this.vbmData.employees[this.Made.user.valuenet_id];
      });

    this.employeeService
      .getAdvisorInfo()
      .then(advisorDetails => this.$scope.advisorDetails = advisorDetails);

    let newsletterDialogPromise = this.mailService.openNewsletterDialogIfUserChoiceIsMissing(this.Made.user.valuenet_id)

    let availableComponentsPromise = this.componentsService
      .getComponents()
      .then(() => this.employeeService.getCustomerId(this.Made.user.valuenet_id))
      .then(async customerId => {
        this.customer_id = customerId;
        return {
          'components': await this.componentsService.applyConfiguration(customerId, true),
          'customerId': customerId
        }
      })
      .then(r => {
        this.$scope.availableComponents = r.components
        return this.AdministrationService.getDashboardOffers(r.customerId).toPromise()
      })
      .then((offers) => {
        if (offers.length) {
          this.dashboardOfferNotification = offers[0];
        }
      });


    this.hasMissingNeoDocuments();
    this.getBonusProjectsNotifications(); // _bonus_notification => BonusProjectNotifications -> Welcome/Standard
    this.getBonusControlProcessNotifications(); // _bonus_control_process_notifications
    this.getCashbackNotifications(); // _cashback_notifications
    this.getBikeleasingConfirmationNotifications(); // _bonus_bikeleasing_confirmation_notifications
    this.getDticketNotification();  // _dticket_notifications
    this.getMissingEVGDocumentsNotification(); // _missing_evg_documents_notification
    this.getApprovalProcessNotifications(); // _approval_process_notifications
    this.getLastNeoStatusChangeNotification(); // _neo_status_change_notification
    this.getBonusStartDateChangeNotifications(); // _bonus_change_begin_date_notifications
    this.getBasketBeginChangeNotifications(); // _basket_begin_change notifications
    this.getBasketControlProcessSucessNotifications(); // basket_control_process_success notifications
    this.getMPPDecisionProcessNotification(); // mpp_decision_process_notifications
    this.getCarLeasingNotifications();
    this.getExternalNotifications();


    this.$q.all([newsletterDialogPromise, availableComponentsPromise, configurationPromise, neoProjectPromise]).then(async () => {
      let hasSteuerFreiExtrasEnabled = this.lodash.get(this.$scope.configuration, 'modules.steuerfreie_extras.enabled', true);
      if (hasSteuerFreiExtrasEnabled) {
        const hasEmployeeActiveSelfServiceComponent = await this.NeoComponentsService.hasEmployeeActiveSelfServiceComponent(this.Made.user.valuenet_id);
        if (hasEmployeeActiveSelfServiceComponent) {
          this.$scope.showSteuerfreiExtrasComponents = true;
        }
      }


      let hasCustomerActivatedPC = await this.NeoComponentsService.hasCustomerActivatedPC({employee_id: this.Made.user.valuenet_id});
      if (hasCustomerActivatedPC) {
        this.$scope.availableComponents.push({
          ...PCComponentModel.DISPLAY,
          to_use_new_route: true
        });

        this.$scope.$timeout(() => {
        });
      }
    });

    this.$scope.transition = (component) => {
      if (component.to_use_new_route) {
        this.$state.go(`inApp.neo.side.moduleNew${component.id}`, {neo_component: null});
      } else {
        this.$state.go(`inApp.neo.side.module${component.id}`);
      }
    };

    this.cmsService
      .getTeaserList(null, 'show_all', 20)
      .then(async items => {

        for (let item of items) {
          if (item.teaser && item.teaser._id) {
            this.styles[item.teaser._id] = {
              'background-image': 'url(' + await this.fileService.getDocumentUrl(item.teaser._id) + ')'
            };
          }

          if (item.stage && item.stage._id) {
            this.styles[item.stage._id] = {
              'background-image': 'url(' + await this.fileService.getDocumentUrl(item.stage._id) + ')'
            };
          }
        }

        this.$timeout(() => {
          this.$scope.cmsItems = items;
        });

      });

    this.vbmData
      .getComponentNames()
      .then(value => {
        this.$scope.componentNames = value;
      });

    // Check if user needs to upload additional documents;
    this.UserNotificationService.getYearlyNotSeenNotifications(this.Made.user.valuenet_id)
      .then((notification) => {
        if (notification) {
          this.$timeout(() => {
            this.yearly_notification = notification;
          }, 0);
        }
      });

    this.CheckoutService
      .checkSession()
      .then(session => this.$scope.checkoutSession = session);

    this.$scope.calculate = (potentialForm) => {
      if (!potentialForm.$valid) {
        return;
      }
      const {brutto, pap_stkl, pap_r, geburtsdatum} = {...this.lodash.get(this.$scope.employee, 'data.parameters', {})};
      this.$scope.saving = true;
      this.employeeService
        .updateBirthdateData({
          'data': {
            'geburtsdatum': geburtsdatum
          }
        }, this.Made.user.valuenet_id)
        .then(() => {
          this.employeeService.get(this.Made.user.valuenet_id, Date.now()).then((employee) => {
            this.vbmData
              .save()
              .then(() => {
                this.potentialCalculatorService
                  .calculatePotential(brutto)
                  .then(potential => this.$scope.potential = potential);
              }, (error) => {
                return this.NotificationService.default.checkoutSession(error);
              })
              .finally(() => this.$scope.saving = false);
          });
        })
        .catch(() => {
          this.$scope.saving = false;
        });
    };

    this.$scope.start = () => this.potentialCalculatorService
      .getPotentialLink()
      .then(address => this.$state.go(address));

    this.$scope.goToDocumentsUpload = () => this.$state.go('inApp.meineakte.missing_documents');
    this.$scope.goToUserDecisions = () => this.$state.go('inApp.meineakte.user_decisions');

    this.$scope.resumeCheckout = () => this.$state.go('inApp.neo.side.checkout.zusammenfassung');

    this.loading = false;
  }

  async goToIncentPortal() {
    // TODO: add loading
    this.loading = true;
    const response = await this.AdministrationService.openOfferLinkRequest(this.dashboardOfferNotification.id).toPromise();
    this.loading = false;
    if (response['is_valid']) {
      this.$window.open(response['url'], '_blank');
    } else {
      this.NotificationService.error('Fehlgeschlagen.​ Bitte kontaktieren Sie Ihren Berater');
    }
  }


  async amIApprover() {
    let amIACLApprover = await this.ACLService.amIApprover();

    this.$timeout(() => {
      this.$scope.iAmApprover = amIACLApprover;
    }, 0);
  }

  async amIEmployer() {
    let amIemployer = await this.ACLService.amIEmployer();
    this.$timeout(() => {
      this.$scope.iAmEmployer = amIemployer;
    }, 0);
  }

  async amIApprover() {
    let amIACLApprover = await this.ACLService.amIApprover();

    this.$timeout(() => {
      this.$scope.iAmApprover = amIACLApprover;
    }, 0);
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  goToBonusDashboard(bonus_project_id, notification) {
    bonus_project_id = bonus_project_id.toString();
    if (notification.data && notification.data.bonus_notification_type === 'welcome_notification') {
      this.UserNotificationService.markNotificationAsSeen(notification._id);
    }
    this.$state.go('inApp.bonus.dashboard.main', {
      bonusProjectId: bonus_project_id
    });

  }

  returnBonusRemainingAmount(bonus_components, employee_bonus_config) {

    let bonus_sum = 0;
    bonus_components.forEach(bonus => {
      // if (!angular.equals(bonus.state, this.BONUS_STATES_BY_NAME['available']['id'])) {
      if (this.BonusService.isComponentUsed(bonus)) {
        bonus_sum += bonus.bonus_value;
      }
    });

    if (bonus_sum != 0) {
      return (employee_bonus_config.bonus.amount || 0) - bonus_sum;
    } else {
      return employee_bonus_config.bonus.amount;
    }

  }

  async hasMissingNeoDocuments() {
    let has_missing_documents = false;

    let missing_pc_component_documents = await this.NeoComponentsService.getNeoComponentsRequiringDocuments({employee_id: this.Made.user.valuenet_id});
    let missing_neo_component_documents = await this.employeeService.getComponentsWithMissingDocumentsList();
    let digital_signing_requests = await this.DigitalSigningService.getRequestsForSigning(this.Made.user.valuenet_id);
    let has_digital_signing_requests = digital_signing_requests.length > 0;
    let hasMissingCarLeasingDocuments = await this.CarLeasingApiService.hasMissingDocuments(this.Made.user.valuenet_id);

    has_missing_documents = hasMissingCarLeasingDocuments || missing_pc_component_documents.length > 0 || (missing_neo_component_documents.components_list && missing_neo_component_documents.components_list.length > 0) || has_digital_signing_requests;
    this.$timeout(() => {
      this.$scope.user.hasMissingDocuments = has_missing_documents;
      this.$scope.user.has_digital_signing_requests = has_digital_signing_requests;
    });
  }


  async getExternalNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(
      this.Made.user.valuenet_id,
      null,
      'external_default',
    )
      .then((externalNotifications) => {
        this.$timeout(() => {

          console.log('externalNotifications ', externalNotifications)
          this.externalNotifications = externalNotifications;
        });
      });
  }

  async getCarLeasingNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(
      this.Made.user.valuenet_id,
      null,
      'car_leasing',
    )
      .then((carLeasingNotifications) => {
        this.$timeout(() => {
          this.carLeasingNotifications = carLeasingNotifications;
        });
      });
  }

  async getBonusStartDateChangeNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(
      this.Made.user.valuenet_id,
      'bonus_start_date_change',
      null,
      true
    )
      .then((bonus_start_date_change_notification) => {
        this.$timeout(() => {
          this.bonus_start_date_change_notification = bonus_start_date_change_notification;
        });
      });
  }

  async getLastNeoStatusChangeNotification() {
    return this.UserNotificationService.getNotSeenNotifications(
      this.Made.user.valuenet_id,
      'status_change',
      null,
      true
    )
      .then((res) => {
        this.$timeout(() => {
          this.neo_status_change_notification = res;
        }, 0);
      });
  }

  async getMPPDecisionProcessNotification() {
    return this.UserNotificationService
      .getNotSeenNotifications(this.Made.user.valuenet_id, NOTIFICATION_TYPES_BY_NAME['mpp_decision_process_notification']['name'])
      .then((notifications) => {
        this.$timeout(() => {
          console.log("notifications", notifications);
          this.mpp_decision_process_notifications = notifications;
        }, 0);
      });
  }

  async getBonusProjectsNotifications() {
    return this.UserNotificationService
      .getBonusProjectsNotifications(this.Made.user.valuenet_id)
      .then((notifications) => {
        this.$timeout(() => {
          this.bonus_project_notifications = notifications;
        }, 0);
      });
  }

  async getBonusControlProcessNotifications() {
    return this.BonusService.getBonusControlProcessNotifications(this.Made.user.valuenet_id).then(
      (res) => {
        this.$timeout(() => {
          this.bonus_control_process_notifications = res;
        }, 0);
      });
  }

  async getCashbackNotifications() {
    return this.BonusService.getCashbackNotifications(this.Made.user.valuenet_id).then(
      (res) => {
        this.$timeout(() => {
          this.cashbackNotifications = res;
        });
      });
  }

  async getBikeleasingConfirmationNotifications() {
    return this.UserNotificationService.getBonusBikeleasingConfirmationNotifications(this.Made.user.valuenet_id).then(
      (res) => {
        this.$timeout(() => {
          this.bonus_bikeleasing_confirmation_notifications = res;
        });
      });
  }

  async getMissingEVGDocumentsNotification() {
    return this.UserNotificationService.getMissingEVGDocumentsNotification(this.Made.user.valuenet_id).then(
      (res) => {
        this.$timeout(() => {
          this.missing_evg_documents_notification = res;
        }, 0);
      });
  }

  async getDticketNotification() {
    return this.UserNotificationService.getDticketNotification(this.Made.user.valuenet_id).then(
      (res) => {
        this.$timeout(() => {
          this.dticket_notifications = res;
        });
      });
  }

  async markDticketNotificationAsSeen(dticket_id) {
    return this.UserNotificationService.markDticketNotificationAsSeen(dticket_id, this.Made.user.valuenet_id)
      .then(async () => {
        await this.getDticketNotification();
      })
      .catch((err) => {
        this.NotificationService.error('Fehlgeschlagen');
      });
  }

  async markMissingEVGDocumentsNotification() {
    return this.UserNotificationService.markMissingEVGDocumentsNotificationsAsSeen(this.Made.user.valuenet_id).then(
      () => {
        this.$timeout(() => {
          this.missing_evg_documents_notification.status = this.VSP_CONSTANTS.NOTIFICATION_STATUS_BY_NAME.seen.name;
        }, 0);
      }).catch((err) => {
      this.NotificationService.error('Fehlgeschlagen');
    });
  }

  async hideBonusNotificationForBonuses(bonuses, notification_type) {
    let bonus_ids = bonuses.map(bonus => bonus._id);
    let operation;
    if (notification_type === this.BONUS_STATES_BY_NAME['declined']['name']) {
      operation = this.BonusService.hideBonusDeclinedNotificationForBonuses(bonus_ids);
    } else {
      operation = this.BonusService.hideBonusApprovedNotificationForBonuses(bonus_ids);
    }

    return operation.then(
      (res) => {
        this.$timeout(() => {
          this.bonus_control_process_notifications[notification_type].is_visible = false;
        }, 0);
        this.NotificationService.message('Die Benachrichtigung wurde als gelesen markiert und wird  nicht mehr erscheinen.');
      });
  }

  async markNotificationAsSeen(notification) {
    return this.UserNotificationService.markNotificationAsSeen(notification._id)
      .then(async () => {
        notification.status = 'seen';
        if (notification.notification_name == 'car_leasing') {
          await this.getCarLeasingNotifications()
        }
        if (notification.type == 'cashback_notification') {
          await this.getCashbackNotifications();
        }
      })
      .catch((err) => {
        this.NotificationService.error('Fehlgeschlagen');
      });
  }

  async getApprovalProcessNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(this.Made.user.valuenet_id, NOTIFICATION_TYPES_BY_NAME['approval_process_notification']['name'])
      .then((res) => {
        this.$timeout(() => {
          this.approval_process_notifications = res;
        }, 0);
      });
  }

  async getBasketBeginChangeNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(this.Made.user.valuenet_id, 'basket_begin_change')
      .then((res) => {
        this.$timeout(() => {
          this.basket_begin_change_notifications = res;
        }, 0);
      });
  }

  openSupportDialog() {
    this.SupportService.openSupportRequestDialog();
  }

  async getBasketControlProcessSucessNotifications() {
    return this.UserNotificationService.getNotSeenNotifications(this.Made.user.valuenet_id, 'basket_control_process_success')
      .then((res) => {
        this.$timeout(() => {
          this.basket_control_process_success_notifications = res;
        }, 0);
      });
  }
}

DashboardArbeitnehmerController.$inject = $inject;
