import {
  BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID,
  COMPONENTS_WITH_DOCUMENTS_BY_ID,
  PROJECT_TAGS_BY_NAME
} from '../../../services/customerService';

import document_dialog_html from '../../../dialogs/missing_document/document_dialog.pug'
import {BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";

const $inject = [
  '$scope',
  'employeeService',
  'CheckoutService',
  'customerService',
  'Made',
  '$q',
  '$state',
  'lodash',
  'vbmData',
  '$timeout',
  'NotificationService',
  'BonusService',
  '$window',
  'fileService',
  'UserNotificationService',
  'dialogService',
  'NeoComponentsService',
  'mppService',
  'userService',
  'VSP_CONSTANTS',
  'API_END_POINTS', // remove
  'shopApiService',
  'DigitalSigningService',
  'CarLeasingApiService',
  'DticketOrderService'
];
export default class MeineAkteMissingDocumentsController {

  constructor(
    $scope,
    employeeService,
    CheckoutService,
    customerService,
    Made,
    $q,
    $state,
    lodash,
    vbmData,
    $timeout,
    NotificationService,
    BonusService,
    $window,
    fileService,
    UserNotificationService,
    dialogService,
    NeoComponentsService,
    mppService,
    userService,
    VSP_CONSTANTS,
    API_END_POINTS,
    shopApiService,
    DigitalSigningService,
    CarLeasingApiService,
    DticketOrderService
  ) {

    this.$scope = $scope;
    this.Made = Made;
    this.employeeService = employeeService;
    this.CheckoutService = CheckoutService;
    this.customerService = customerService;
    this.NeoComponentsService = NeoComponentsService;
    this.$q = $q;
    this.$state = $state;
    this.lodash = lodash;
    this.vbmData = vbmData;
    this.$timeout = $timeout;
    this.NotificationService = NotificationService;
    this.BonusService = BonusService;
    this.$window = $window;
    this.fileService = fileService;
    this.UserNotificationService = UserNotificationService;
    this.mppService = mppService;
    this.documents_declined_notification = null;
    this.employeeId = this.vbmData.employeeId;
    this.dialogService = dialogService;
    this.userService = userService;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.API_END_POINTS = API_END_POINTS;
    this.shopApiService = shopApiService;
    this.DigitalSigningService = DigitalSigningService;
    this.CarLeasingApiService = CarLeasingApiService;
    this.DticketOrderService = DticketOrderService;

    this.missingDocumentsList = [];
    this.filesForDigitalSigning = [];
    this.digitalSignitureCheckPassed = false;
    this.has_missing_documents = true;
    this.camera = {
      video: '',
      canvas: '',
      stream: '',
      img: '',
      name: ''
    };
    this.loading = {
      main:true,
      generating_document: false,
      uploading_documents: false
    };

    this.carLeasingReUpload = {};
    this.carContracts = {};
    this.DOCUMENT_TYPES = PROJECT_TAGS_BY_NAME;

    this.DOCUMENT_SUBTYPES_BY_TYPE = {
      'bonus': BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID,
      'neo': COMPONENTS_WITH_DOCUMENTS_BY_ID
    };
    this.BONUS_STATES_BY_NAME = BONUS_STATES_BY_NAME;

    this.init().finally(()=>{
      this.$timeout(()=>{
        this.loading.main = false;
      });
    });
  }

  async init() {
    let promises = [];

    promises.push(
      this.customerService
        .getConfiguration(this.customerId)
        .then(configuration => {
          this.configuration = configuration;
        })
    );
    promises.push(this.loadMissingDocuments());
    return this.$q.all(promises);
  }

  async loadMissingDocuments() {
    let calculation_components_requiring_documents_promise = this.getComponentNames().then(() => { return this.loadComponentsWithMissingDocumentsList(); });
    let bonus_components_requiring_documents_promise = this.getBonusComponentsMap().then(()=> { return this.getBonusesRequiringDocuments();});
    let standalone_component_requiring_documents_promise = this.getNeoComponentsRequiringDocuments();
    let files_for_digital_signing_promise = this.getFilesForDigitalSigning();
    let has_car_leasing_documents_requests_promise = this.getCarsWithDocumentsRequests();
    let has_car_ready_to_sign_promise = this.getCarsReadyToSign();

    let had_dticket_order_documents_ready_to_sign_promise = this.getDTicketOrderDocumentsReadyToSign();

    return this.$q.all([
      calculation_components_requiring_documents_promise,
      bonus_components_requiring_documents_promise,
      standalone_component_requiring_documents_promise,
      files_for_digital_signing_promise,
      has_car_leasing_documents_requests_promise,
      has_car_ready_to_sign_promise,
      had_dticket_order_documents_ready_to_sign_promise
    ]).then(()=>{
      let has_calculation_components_requiring_documents = this.missingDocumentsList.length > 0;
      let has_bonus_components_requiring_documents = this.bonuses.length > 0;
      let has_standalong_components_requiring_documents = this.neo_components.length > 0;
      let has_files_for_digital_signing = this.files_for_digital_signing.length > 0;
      this.has_missing_documents =
        has_bonus_components_requiring_documents ||
        has_calculation_components_requiring_documents ||
        has_standalong_components_requiring_documents ||
        has_files_for_digital_signing ||
        has_car_leasing_documents_requests_promise.length > 0 ||
        had_dticket_order_documents_ready_to_sign_promise.length > 0;
      });
  }

  openDocument(document){
    this.fileService.openDocument(document._id);
  }

  async expandToggler(resource, type) {
    this.$timeout(() => {
      this.loading.generating_document = true;
    }, 0);

    let toShowDropzone = true;

    if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS) {
      if (this.toShowBonusContractButton(resource.state)) {
        toShowDropzone = false;
      }
    } else if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC) {
        toShowDropzone = false;
    } else if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING) {
        toShowDropzone = false;
    } else if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET) {
        toShowDropzone = false;
    }

    this.$timeout(() => {
      resource.showDropZone = toShowDropzone;
      this.loading.generating_document = false;
    }, 0);
  }

  getComponentNames() {
    return this.vbmData.getComponentNames().then(value => {
      this.componentNames = value;
    });
  }

  loadComponentsWithMissingDocumentsList(updateStatusOnEmpty = false) {
    return this.employeeService.getComponentsWithMissingDocumentsList().then(result => {
      if (result.components_list.length === 0) {
        if (updateStatusOnEmpty) {
          return this.employeeService.noMoreMissingDocumentsForEmployee(this.employeeId).catch(error => {
            console.error(error);
          });
        }
      } else {
        this.sessionId = result.session_id;
        let componentsList = result.components_list;
        let missingDocumentsList = [];

        return this.UserNotificationService.getNotSeenNotifications(
          this.Made.user.valuenet_id,
          'status_change',
          'documents_declined',
          true
        ).then(
          (notification) => {
            this.documents_declined_notification = notification;
            //creates an array with all missing documents and adds a comment if there the documents decline notification contains the component
            angular.forEach(componentsList, (component) => {
              missingDocumentsList.push({
                uploaded: [],
                for_component: component,
                component_name: this.componentNames[component],
                comment: (notification && notification.data.components && notification.data.components[component] && notification.data.components[component].comment) ? notification.data.components[component].comment : null
              });
            });

            this.missingDocumentsList = missingDocumentsList;
          });
      }
    });
  }

  async getNeoComponentsRequiringDocuments() {
    this.neo_components = await this.NeoComponentsService.getNeoComponentsRequiringDocuments({employee_id: this.employeeId});
    console.warn(this.neo_components)
    this.$timeout(()=>{});
  }

  getBonusesRequiringDocuments() {
    return this.BonusService.getBonusesRequiringDocuments(this.employeeId).then(
      (bonuses) => {
        this.bonuses = bonuses.map((bonus)=>{
          bonus['files'] = [];
          return bonus;
        });
      });
  }

  async openDocDialog(resource, type = 'pc') {
    resource.showDropZone = false;
    this.$timeout(()=>{
      this.loading.generating_document = true;
    });
    let generatedContractResponse = null;
    let resource_id = null;
    if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS) {
      let data = await this.BonusService.generateBonusCheckoutContractByBonusIdSmartAnchors(this.employeeId, resource.bonus_id);
      generatedContractResponse = data.response;
      resource_id = resource.bonus_id;
    } else if (type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC) {
      let data = await resource.generateContract();
      generatedContractResponse = data.response;
      resource_id = resource.basket.id;
    } else if (type == this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BO_REQUEST) {
      generatedContractResponse = await this.fileService.getDocumentUrl(resource._id);
      resource_id = resource._id;
    } else if (type == this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING) {
      generatedContractResponse = await this.CarLeasingApiService.generateContract(resource.id)
      resource_id = resource.id
    } else if (type == this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET) {
      generatedContractResponse = await this.DticketOrderService.generateCheckedOutContractPreview(resource.id)
      resource_id = resource.id
    }

    let digitalEnabledComp = this.DigitalSigningService.getEnabledComponentsConfigByType(this.configuration, type)
    let canUseDigitalSigniture = digitalEnabledComp.enabled
    let canSignManually = digitalEnabledComp ? digitalEnabledComp.canSignManually : false

    let dialogData = {
      pdfGeneratorUrl: generatedContractResponse,
      employeeId: this.employeeId,
      customerId: this.configuration['_id'],
      canSignManually: canSignManually,
      resourceId: resource_id,
      canUseDigitalSigniture: canUseDigitalSigniture,
      comp_type: type,
    };

    console.log("passed data", dialogData);

    this.$timeout(()=>{
      this.loading.generating_document = false;
    });
    this.dialogService.ngDialog.openConfirm({
      template: document_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'MissingDocumentDialogController',
      data: dialogData,
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: false,
      closeByEscape: true,
      closeByNavigation: true
    }).then(async (res) => {
      if (res.action === 'reload') {
        this.loading.main = true;
        await this.loadMissingDocuments()
        this.loading.main = false;
      }
      resource.showDropZone = res && res.action && res.action === 'download';
    });

  }

  generateBonusContractByBonusId(bonus_id) {
    this.loading.generating_document = true;
    this.BonusService.generateBonusCheckoutContractByBonusId(this.employeeId, bonus_id).then(
      (res) => {
        this.$timeout(()=>{
          this.loading.generating_document = false;
        });
        this.$window.open(res.response, '_blank');
      }).catch((err) => {
      console.error(err);
    });
  }

  getBonusComponentsMap () {
    return this.BonusService.getAvailableBonusComponents().then((res) => {
      this.BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {});
    });
  }

  async uploadNeoComponentDocuments() {
    let promises = [];

    for (let neo_component of this.neo_components) {
      promises.push(
        neo_component.uploadDocuments()
      );
    }

    return this.$q.all(promises);
  }

  uploadDocuments() {
    this.loading.uploading_documents = true;
    let promises = [];
    promises.push(this.uploadNeoDocuments());
    promises.push(this.uploadBonusDocuments());
    promises.push(this.uploadNeoComponentDocuments());
    promises.push(this.uploadCarLeasingFiles());
    promises.push(this.uploadCarLeasingSignedContract())

    this.$q.all(promises).then(
      (res) => {
        this.$timeout(()=>{
          this.loading.uploading_documents = false;
        });
        this.NotificationService.message('Upload erfolgreich.');
        this.$state.reload();
      }).catch((err) => {
        if (err.code && err.code === 'car_leasing_missing_documents') {
          alert("ERROR: " + err.message)
          this.$timeout(()=>{
            this.loading.uploading_documents = false;
          });
        } else {
          this.NotificationService.error('Upload fehlgeschlagen.');
        }
    });
  }

  async uploadCarLeasingSignedContract() {
    for (const [carId, contract] of Object.entries(this.carContracts)) {
      let uploadedFileId = await this.CarLeasingApiService.carLeasingEmployeeUploadContract(
        carId,
        contract
      )
      await this.CarLeasingApiService.triggerContractSigningProcess(
        carId,
        uploadedFileId
      )
    }
  }


  async uploadCarLeasingFiles() {
    for (const [basketId, files] of Object.entries(this.carLeasingReUpload)) {
      for (const [slot, f] of Object.entries(files)) {
        if (!f) {
          const error = new Error('CarLeasing Files by slot [' + slot + '] are required!');
          error.code = 'car_leasing_missing_documents';
          throw error;
        }
      }
    }

    for (const [basketId, files] of Object.entries(this.carLeasingReUpload)) {
      let filesBySlot = await this.CarLeasingApiService.uploadRequestedCarLeasingFiles(
        this.employeeId,
        files,
        basketId,
      );
      await this.CarLeasingApiService.triggerReuploadEvent(basketId, filesBySlot)
    }
  }

  uploadNeoDocuments() {
    let tags = [this.sessionId, 'done', 'later'];
    let neoDocuments = {};
    let promises = [];
    angular.forEach(this.missingDocumentsList, document => {
      // check if uploaded document exists
      if (!document.uploaded){
        return;
      }
      if (document.uploaded.length === 0) {
        return;
      }

      neoDocuments[document.for_component] = document.uploaded;
      // check if it is salary or NeoComponent
      // if (document.for_component === 'salaryStatement') {
      //   promises.push(this.CheckoutService.setSalaryStatement(document.uploaded, this.Made.user.valuenet_id, tags));
      // } else {
      // }
    });

    // upload
    promises.push(this.CheckoutService.setNeoDocuments(neoDocuments, this.Made.user.valuenet_id, tags));

    return this.$q.all(promises).then((response) => {
      if (this.documents_declined_notification) {
        let documents_missing = false;
        //once there are no more missing documents left in the list the documents declined notification is marked as read
        angular.forEach(this.missingDocumentsList, document => {
          if (!document.uploaded){
            documents_missing = true;
          }
        });
        if(!documents_missing){
          this.UserNotificationService.markNotificationAsSeen(this.documents_declined_notification._id).then(
            (res) => {
              //TODO: Refactor notification status with refactoring of the notifications
              this.documents_declined_notification.status = 'seen';
            }).catch((err) => {
            console.error(err);
          });
        }
      }

      return this.loadComponentsWithMissingDocumentsList(true);
      // this.AlertService.message('Speichern erfolgreich.');
    });
  }

  uploadBonusDocuments() {
    let bonus_promises = [];

    angular.forEach((this.bonuses), (bonus) => {
      if (bonus.files && bonus.files.length > 0) {
        let bonus_files_promises = [];
        let tags = [
          'bonus',
          'bonus_id-' + bonus.bonus_id,
          'bonus_component_name-' + bonus.component,
          'bonus_project_id-' + bonus.bonus_project_id,
          'checkout_session_id-' + bonus.checkout_session.id,
          'bonus_history-' + bonus.bonus_history_id,
          'done'
        ];

        if (bonus.state === BONUS_STATES_BY_NAME['checkout_finished'].id) {
          tags.push('doc_type-user_upload_checkout_contract_main');
        } else if (bonus.state === BONUS_STATES_BY_NAME['amendment'].id) {
          tags.push('doc_type-amendment_user_upload_for_bonus');
        } else if (bonus.state === BONUS_STATES_BY_NAME['amendment_first_check'].id) {
          tags.push('doc_type-amendment_first_check_user_upload_for_bonus');
        }

        for (let [i,file] of bonus.files.entries()) {
          bonus_files_promises.push(this.Made.upload(file, [...tags, ...[`upload_index-${i}`]]));
        }

        bonus_promises.push(this.$q.all(bonus_files_promises).then(
          (res) => {
            return this.BonusService.updateBonusFromAmendment(bonus.bonus_id).then(
              (update_bonus_state_res) => {
                return update_bonus_state_res;
              }).catch((err) => {
              console.error(err);
            });
          }).catch((err) => {
            console.error(err);
          })
        );
      }
    });

    return this.$q.all(bonus_promises).then((response) => {
      return response;
    }).catch((err) => {
      console.error(err);
    });
  }

  getComponentDisplayCheckoutDate(bonus) {
    return this.BonusService.getComponentDisplayCheckoutDate(bonus);
  }

  generateNeoComponentContract(neo_component) {
    neo_component.generateContract().then((res)=>{
      this.$window.open(res.response, '_blank');
    });
  }

  toShowCommentNeoComponent(neo_component) {
    return neo_component.logs && neo_component.logs.length > 0;
  }

  /**
   * @param {PCComponentModel} neo_component
   * @returns {Promise<void>}
   */
  async getMPPBasketLogsForCPAmendment(neo_component) {
    // #COMMENT_LEX: logs is not declared on th PCComponent (or should it be on the Component?) and I have the feeling
    // it doesn't belong there. It would probably be clearer to actually return the logs by this method (its name
    // starts with get!)

    //Refactor hard coded value for the reason id
    neo_component.logs = await this.shopApiService.getPcBasketLogs(neo_component.basket.id, 19, true);
  }

  toShowCommentBonus(bonus) {
    if (bonus.state === this.BONUS_STATES_BY_NAME['amendment']['id'] || bonus.state === this.BONUS_STATES_BY_NAME['amendment_first_check']['id']) {
      return true;
    }
    return false;
  }

  toShowBonusContractButton(state) {
    if (state === this.BONUS_STATES_BY_NAME['checkout_finished']['id'] || state === this.BONUS_STATES_BY_NAME['amendment']['id']) {
      return true;
    }
    return false;
  }

  async getFilesForDigitalSigning() {
    this.files_for_digital_signing = await this.DigitalSigningService.getRequestsForSigning(this.employeeId);
  }

  async getCarsWithDocumentsRequests() {
    this.carsWithDocumentRequests = await this.CarLeasingApiService.getBasketsAwaitingDrivingLicenceUpload(this.employeeId);
    this.carsWithDocumentRequests.forEach(car => {
      this.carLeasingReUpload[car.id] = {};
      for (const [slotId, data] of Object.entries(car.firstCheckFiles.uploadRequestsBySlotId)) {
        this.carLeasingReUpload[car.id][slotId] = null;
      }
    });

    return this.carsWithDocumentRequests;
  }

  async getCarsReadyToSign() {
    this.carsReadyToSign = await this.CarLeasingApiService.getBasketsReadyToSign(this.employeeId);
    return this.carsReadyToSign;
  }

  async getDTicketOrderDocumentsReadyToSign() {
    this.dTicketOrdersReadyToSign = await this.DticketOrderService.getDTicketOrdersReadyToSign(this.employeeId);
    return this.dTicketOrdersReadyToSign;
  }

  openFile(file_id){
    this.fileService.openDocument(file_id);
  }

  switchCamera = (status, slot='', id='') => {
    if (status) {
      this.camera = {
        ...this.camera,
        video: document.querySelector(`.camera.${slot}_${id} video`),
        canvas: document.querySelector(`.camera.${slot}_${id} canvas`),
        img: document.querySelector(`.camera.${slot}_${id} img`),
        name: `${slot}_${id}`,
      }

      this.camera.canvas.width = 400;
      this.camera.canvas.height = 300;

      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(stream => {
          this.camera.stream = stream;
          this.camera.video.srcObject = stream;
          this.camera.video.play();
      })
      .catch(err => {
        this.NotificationService.error('Camera error!');
        console.log("Camera error: " + err);
      });
    } else {
      this.camera.video.pause();
      this.camera.video.src = "";
      this.camera.stream.getTracks()[0].stop();

      this.camera = {
        ...this.camera,
        video: '',
        canvas: '',
        img: '',
        name: '',
      }
    }
  }

  async takePhoto (slot, id) {
    await this.camera.canvas.getContext('2d').drawImage(this.camera.video, 0, 0, 400, 300);
    let imgData = await this.camera.canvas.toDataURL('image/png');
    this.camera.img.setAttribute('src', imgData);

    this.camera.canvas.toBlob((blob) => {
      this.carLeasingReUpload[id][slot] = blob;
      this.switchCamera(false);
    });
    this.camera.name = '';
  }

  mapNamesToGerman (name) {
    let names = {
      'front': 'VORDERSEITE',
      'back': 'RÜCKSEITE - HOLOGRAMM ZAHLEN',
      'selfie': 'RÜCKSEITE - HOLOGRAMM SYMBOLE',
    }

    return names[name];
  }
}

MeineAkteMissingDocumentsController.$inject = $inject;
