import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface NewsletterDialog {
  showCloseButton?: boolean
}

@Component({
  selector: 'vn-newsletter-dialog',
  templateUrl: './newsletter-dialog.component.html',
  styleUrls: ['./newsletter-dialog.component.scss']
})
export class NewsletterDialogComponent implements OnInit {
  selectedOption: string = 'yes';
  showCloseButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<NewsletterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: NewsletterDialog
  ) {
    this.showCloseButton = this.dialogData.showCloseButton || false;
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close(this.selectedOption);
  }

  closeWithoutSelection(): void {
    this.dialogRef.close(null);
  }

}
