import { DrivingLicenseFileType } from "../../../../../app/car-leasing/models/driving-license-file-type.model";
import moment from 'moment';
import {DigitalSignatureYouSignServiceV3} from "../../../../../app/shared/services/digital-signature-you-sign.service";

const $inject = [
  '$scope',
  '$state',
  '$http',
  'employeeService',
  'Made',
  'WizardHandler',
  '$timeout',
  'NotificationService',
  'lodash',
  'authenticationService',
  'CheckoutService',
  'CarLeasingApiService',
  'PDFJS',
  'DigitalSigningService',
  'VSP_CONSTANTS',
  'ByteplantService',
  'DigitalSignatureYouSignServiceV3'
];

export class CarLeasingCheckoutController {
  constructor(
    $scope,
    $state,
    $http,
    employeeService,
    Made,
    WizardHandler,
    $timeout,
    NotificationService,
    lodash,
    authenticationService,
    CheckoutService,
    CarLeasingApiService,
    PDFJS,
    DigitalSigningService,
    VSP_CONSTANTS,
    ByteplantService,
    DigitalSignatureYouSignServiceV3
  ) {
    Object.assign(this, {
      $scope,
      $state,
      $http,
      employeeService,
      Made,
      WizardHandler,
      $timeout,
      NotificationService,
      lodash,
      authenticationService,
      CheckoutService,
      CarLeasingApiService,
      PDFJS,
      DigitalSigningService,
      VSP_CONSTANTS,
      ByteplantService,
      DigitalSignatureYouSignServiceV3
    });

    this.isPrufenButtonDisabled = false;
    this.isPrufenButtonProved = false;
    this.showPrufenErrorMessage = false;

    this.forms = {
      deliveryDataForm: null
    };
    this.$scope.forms = this.forms;

    this.deliveryData = null;

    this.camera = {
      video: '',
      canvas: '',
      stream: '',
      img: '',
      name: ''
    }
    this.drivingLicenseFiles = {
      front: null,
      back: null,
      selfie: null
    };

    this.basketId = null;
    this.digitalSigniture = false;

    this.isPhoneValid = false;
    this.PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
  }

  switchCamera = (status, name = '') => {
    if (status) {
      this.camera = {
        ...this.camera,
        video: document.querySelector(`.camera.${name} video`),
        canvas: document.querySelector(`.camera.${name} canvas`),
        img: document.querySelector(`.camera.${name} img`),
        name: name,
      }

      this.camera.canvas.width = 400;
      this.camera.canvas.height = 300;

      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(stream => {
          this.camera.stream = stream;
          this.camera.video.srcObject = stream;
          this.camera.video.play();
        })
        .catch(err => {
          this.NotificationService.error('Camera error!');
          console.log("Camera error: " + err);
        });
    } else {
      this.camera.video.pause();
      this.camera.video.src = "";
      this.camera.stream.getTracks()[0].stop();

      this.camera = {
        ...this.camera,
        video: '',
        canvas: '',
        img: '',
        name: '',
      }
    }
  }

  async takePhoto(camera) {
    await this.camera.canvas.getContext('2d').drawImage(this.camera.video, 0, 0, 400, 300);
    let imgData = await this.camera.canvas.toDataURL('image/png');
    this.camera.img.setAttribute('src', imgData);

    this.camera.canvas.toBlob((blob) => {
      this.drivingLicenseFiles[camera] = new File(
        [blob],
        `${camera}_driving_file.png`,
        { type: 'image/png' }
      );
      this.switchCamera(false);
    });
    this.camera.name = '';
  }

  async $onInit() {
    this.__loading(true);
    this.checkoutConfiguration = this.$state.params.carLeasingCheckoutConfiguration;
    this.carAdvantageResult = this.$state.params.carAdvantageResult
    this.userSelection = this.$state.params.userSelection;

    if (!this.checkoutConfiguration || !this.userSelection || !this.carAdvantageResult) {
      this.$state.go('inApp.car-leasing.list');
    } else {
      this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
      this.employeeId = this.Made.user.valuenet_id;
      this.customerId = this.Made.user.customer_id;
      this.isTestClient = this.Made.user.test_client;

      this.employee = await this.employeeService.get(this.employeeId, Date.now());
      this.employeeAddress = { ...this.employee.address };
      this.deliveryData = {
        name: this.employee.firstname,
        lastname: this.employee.lastname,
        city: this.lodash.get(this.employee, 'address.city'),
        street: this.lodash.get(this.employee, 'address.street'),
        zip: this.lodash.get(this.employee, 'address.zip'),
        phone: this.lodash.get(this.employee, 'contact_details.mobilePrivate'),
        comment: '',
        preferredDeliveryDate: '',
      }

      this.step = this.WizardHandler.wizard().currentStepNumber;
      this.steps = this.WizardHandler.wizard().totalStepCount;

      this.$scope.$watch('forms.deliveryDataForm.$invalid', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (newVal) {
            this.isPrufenButtonProved = this.isDeliveryDataValid();
          }
        }
      });

    }

    this.__loading(false);
  }

  updateAddressInput() {
    this.isPrufenButtonProved = false;
  }

  higherAgeNeeded() {
    return this.checkoutConfiguration.power > 150;
  }

  navigateToCarLeasingShop() {
    this.$state.go('inApp.car-leasing.list')
  }

  navigateToDashboard(reload = true) {
    this.$state.go('inApp.neo.side.verguetung', { reload: reload });
  }

  handleUserInfoFormSubmit() {
    this.__loading(true);
    let address = {
      ort: this.employeeAddress.city,
      plz: this.employeeAddress.zip,
      strasse: this.employeeAddress.street
    };

    this.employeeService.updateAddressData({ address: address }, this.employeeId).then((res) => {
      this.__loading(false);
      this.WizardHandler.wizard().next();
    }).catch((err) => {
      console.log(err);
    });
  }

  async generateCarleasingPreContract() {
    this.proceed();
    this.__loading(true);
    try {
      let drivingFiles = [
        { type: DrivingLicenseFileType.Front, file: this.drivingLicenseFiles.front },
        { type: DrivingLicenseFileType.Back, file: this.drivingLicenseFiles.back },
        { type: DrivingLicenseFileType.Selfie, file: this.drivingLicenseFiles.selfie },
      ];
      this.basketId = await this.CarLeasingApiService.createBasket(
        this.employeeId,
        this.checkoutConfiguration,
        drivingFiles,
        this.deliveryData,
        this.userSelection
      );

      this.contract_url = await this.CarLeasingApiService.generateContract(this.basketId);

    } catch (e) {
      let error = e.error
    }
    this.__loading(false);
  }

  async sign(){
    this.proceed();
    this.__loading(true);

    let res = null;

    try {
      // @deprecated v2
      // res = await this.DigitalSigningService.signDocumentDigitally({
      //   employee_id: this.employeeId,
      //   start_with: this.contract_url,
      //   customer_id: this.customerId,
      //   resource_id: this.basketId,
      //   type: this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING_PRE_CONTRACT,
      // });
        res = await this.DigitalSignatureYouSignServiceV3.initiate(this.employeeId, this.contract_url, this.basketId, this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING_PRE_CONTRACT);
        console.log("yousign v3 - res:", res);

    } catch (e) {
      this.NotificationService.error("Ihre Bestellung kann nicht erfolgreich ausgeführt werden, da der Vorvertrag nicht unterzeichnet wurde.");

    }

    if (res) {
      this.$timeout(async () =>  {
        if (!res.is_signed) {
          this.signUrl = res.url;
          this.digitalSigniture = true;
          // const iframe = document.getElementById('pdf-digital-viewer');
          //
          // iframe.addEventListener('load', () => {
          //   this.__loading(false);
          // });
        } else {
          this.NotificationService.message("Der Signaturvorgang für dieses Dokument ist bereits abgeschlossen.");
          this.navigateToDashboard();
        }
      });

    }
    this.__loading(false);
  }

  proceed() {
    this.WizardHandler.wizard().next();
  }

  wizardCanceled() {
    this.navigateToCarLeasingShop();
  }

  __loading(val) {
    this.$timeout(() => {
      this.loading = val;
    });
  }

  updateDeliveryPhoneNumber(phoneNumber) {
    this.deliveryData.phone = phoneNumber;
    if(phoneNumber) {
      this.isPhoneValid = true;
    } else {
      this.isPhoneValid = false;
    }
  }

  is2ndStepInvalid() {
    return this.forms.deliveryDataForm.$invalid || (!this.isPhoneValid && !this.isTestClient);
  }

  isDeliveryDataValid() {
    if (
      this.forms.deliveryDataForm['$ctrl.deliveryData.name'].$invalid ||
      this.forms.deliveryDataForm['$ctrl.deliveryData.lastname'].$invalid
    ) {
      return false;
    }

    return this.forms.deliveryDataForm['$ctrl.deliveryData.street'].$valid &&
      this.forms.deliveryDataForm['$ctrl.deliveryData.zip'].$valid &&
      this.forms.deliveryDataForm['$ctrl.deliveryData.city'].$valid
  }

  async prufenClicked(isClicked) {
    this.isPrufenButtonDisabled = true; // loading starts
    const { city, street, zip, } = this.deliveryData
    const deliveryData = {
      street: street,
      city: city,
      zip: zip
    }

    const promiseResult = await this.CarLeasingApiService.validateCheckoutAddresses(
      this.employeeId,
      deliveryData
    );

    this.isPrufenButtonProved = promiseResult;
    this.showPrufenErrorMessage = !this.isPrufenButtonProved;
    this.isPrufenButtonDisabled = false; // loading ends
  }
}

CarLeasingCheckoutController.$inject = $inject;
