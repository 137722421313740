import { BonusComponentController } from './BonusComponentController';
import { MONTHS_IN_YEAR } from '../../../services/BonusService'
import { max } from 'lodash';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerSachbezug extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {
    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;

    this.baseInitPromise.then(() => {
      this.hasSachbezugCheckouts = (
        this.allSachbezugCheckouts['sachbezug'] && this.allSachbezugCheckouts['sachbezug'].length > 0 ||
        this.allSachbezugCheckouts['sachbezug_profit'] && this.allSachbezugCheckouts['sachbezug_profit'].length > 0 ||
        this.allSachbezugCheckouts['sachbezug_pluxee'] && this.allSachbezugCheckouts['sachbezug_pluxee'].length > 0
      );
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      if (!this.model.sliders) {

        let sliders = {
          advantage: angular.copy(this.SliderOptionsConstants),
          months: angular.copy(this.SliderOptionsConstants)
        };

        // if it was set by assistant but you enter for the first time
        if (this.model.max_advantage_value === undefined) {
          let remaining_amount = await this.calculateRemainingWithoutCurrentBonus();
          this.max_advantage_value = (await this.BonusService.getMaxValues(
            this.bonus_component_name,
            this.moment().year())
          )[this.bonus_component_name];

          this.max_advantage_value = Math.min(this.max_advantage_value || Number.MAX_SAFE_INTEGER, this.component_budget_config.range.max_eq, remaining_amount);

        } else {
          this.max_advantage_value = this.model.max_advantage_value;
        }

        let advantage_slider_config = this.BonusService.createSliderConfig(this.max_advantage_value, 0);
        Object.assign(sliders.advantage, advantage_slider_config);
        let advantage = Math.min(this.component_budget_config.value, this.max_advantage_value);

        if (this.model.max_months === undefined || this.model.max_months === null) {
          let available_months = Math.floor(this.employee_bonus_checkout_config.remaining_amount / this.component_budget_config.value);
          if (isNaN(available_months) || available_months < 0) {
            available_months = 0;
          }
          this.max_months = Math.max(Math.min(this.bonus_project_duration_in_months, available_months), 0);
        } else {
          this.max_months = this.model.max_months;
        }

        Object.assign(sliders.months, {
          to: this.max_months,
          step: 1,
          from: 0
        });

        this.model.sliders = sliders;

        this.setModelValues({
          advantage: advantage,
          months: 0
        });

        // everything has been set by the optimization
        if (!this.model.is_optimized) {
          await this.calculate();
        }

      } else {
        // recalculate modelLabels which were deleted before save
        // this.BonusService.recalculateSlidersModelLabels(this.model.sliders);

        this.setModelValues({
          advantage: this.component_budget_config.value,
          months: 1
        });

        this.setMaxMonths(false);
      }

      this.calculateMonthsDifference();

      if (this.model.advantage && this.model.advantage === 0) {
        this.model.advantage = 1;
        // this.wizardCanceled();
      }

      this.advantage_watcher = this.$scope.$watch(() => { return this.model.advantage; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.setMaxMonths();
            this.calculateValue();
            this.calculateMonthsDifference();
          }, 0);
        }
      });

      this.months_watcher = this.$scope.$watch(() => { return this.model.months; }, (newValue, oldValue) => {
        if (newValue === null) {
          newValue = 0;
        }
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.calculateValue();
            this.calculateMonthsDifference();
            console.log("months value changed")
          }, 0);
        }
      });

      this.$scope.$on('$destroy', () => {
        this.advantage_watcher();
        this.months_watcher();
        // this.remaining_amount();
      });

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }
    });
  }

  async init() {
    this.bonus_project_end_date = this.moment.utc(this.bonus_project.dates.bonus_project_end_date);
    let bonus_project_end_date_for_diff = this.bonus_project_end_date.add(1, 'days');
    this.max_available_months = bonus_project_end_date_for_diff.diff(this.moment.utc(this.available_bonus_start_date), 'months');

    return this.employeeService
      .get(this.vbmData.employeeId)
      .then(user => this.user = user);
  }


  setMaxMonths(to_reset_current = true) {
    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    let max_months = Math.floor(remaining_amount / Math.max(this.calculatePerMonthValue(), 1));
    max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR, this.max_available_months), 0);

    if (max_months === 0) {
      this.model.sliders.months.to = 0;
      this.model.sliders.months.from = 0;
      this.model.sliders.months.step = 0;
      return
    }

    if (this.model.sliders.months.to !== max_months) {
      this.model.sliders.months.to = max_months;
    }

    if (to_reset_current) {
      this.model.months = 0;
    }
    if (this.component_budget_config.minimum_months_period) {
      this.model.sliders.months.from = this.component_budget_config.minimum_months_period;

      if (this.model.sliders.months.from >= this.model.sliders.months.to) {
        this.model.sliders.months.from = 0;
      }
    }
  }

  calculatePerMonthValue() {
    let per_month_value = this.model.advantage;
    this.model.per_month_value = per_month_value;
    return this.model.per_month_value;
  }

  calculate() {
    this.setMaxMonths();
    this.calculateValue();
  }

  calculateValue() {
    if (this.model.months === undefined || this.model.months === null) {
      this.model.months = 0;
    }
    this.model.value = this.calculatePerMonthValue() * this.model.months;
    this.model.duration_months = this.model.months;
  }

  setModelValues(model_default_values) {
    super.setModelValues(model_default_values);
    this.model.sliders.advantage.from = this.component_budget_config.value;
    this.model.sliders.advantage.to = this.component_budget_config.range.max_eq

    if(this.model.sliders.advantage.from === this.model.sliders.advantage.to) {
      this.model.sliders.advantage.from = 0;
    }
  }
}

BonusComponentControllerSachbezug.$inject = $inject;
