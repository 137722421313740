import { Inject, Injectable } from '@angular/core';
import { BonagoCategory, BonagoRetailerBasic, BonagoRetailerAssets, BonagoRetailerInformation, BonagoRetailerInformationType, BonagoRetailerLogo, BonagoRetailerLogoType, BonagoScheme, BonagoRetailer, BonagoVoucher, BonagoUserChecks, BonagoCustomerChecks, EmployeeAddress, BonagoRetailerAddress } from '../models/benefit-bonago.model';

@Injectable()
export class BenefitsBonagoMapper {

  constructor(
    @Inject('Made') private made: any,
  ) { }

  public toBonagoSchemes(data: any[]): BonagoScheme[] {
    return data.map(
      rawObject => ({
        id: rawObject['_id'],
        name: rawObject['name'],
        display: rawObject['display'],
        description: rawObject['description'],
        schemeId: rawObject['scheme_id'],
        languageCode: rawObject['language_code'],
        countryCode: rawObject['country_code'],
        defaultPostcode: rawObject['default_postcode'],
        enableGoogleMaps: rawObject['enable_google_maps'] || false,
      })
    );
  }

  public toBonagoUserChecks(rawObject: any): BonagoUserChecks {
    return {
      valuenetId: rawObject['valuenet_id'],
      userCodeSchemeId: rawObject['checks']['user_code_scheme_id'],
      hasValidUserCode: rawObject['checks']['has_valid_user_code'],
      hasBonagoEmployeeBenefit: rawObject['checks']['employee_has_sachbezug_with_bonago'],
    };
  }

  public toBonagoCustomerChecks(rawObject: any): BonagoCustomerChecks {
    return {
      hasSchemeId: rawObject['customer_has_scheme_id'],
      hasAllowedBonago: rawObject['customer_has_allowed_bonago'],
    };
  }

  public toBonagoCategories(data: any[]): BonagoCategory[] {
    return data.map(
      rawObject => ({
        id: rawObject['Id'],
        name: rawObject['Name'],
        schemeId: 0,
        // schemeId: rawObject['scheme_id'],
      })
    );
  }

  public toBonagoRetailer(data: any): BonagoRetailer {
    const bonagoRetailerBasic = this.toBonagoRetailerBasic(data);

    return {
      ...bonagoRetailerBasic,
      vouchers: (data['retailer']['Vouchers'][0]['VoucherItems'] as any[]).map(
        (rawObject: any) => ({
          currencyCode: rawObject['CurrencyCode'],
          displayValue: rawObject['Description'],
          id: rawObject['Sku'],
          voucherAmount: rawObject['Denomination'],
          voucherType: rawObject['VoucherType'],
        }) as BonagoVoucher
      ),
    }
  }

  private getRetailerTitle(rawName: string) {
    if (rawName.includes('(')) {
      let trimmedName = rawName.split('(')[0]
      return trimmedName.split('_').join(' ');
    } else if (rawName.includes('_')) {
      return rawName.split('_')[0];
    }

    return rawName;
  }

  private getRetailerSubtitle(rawName: string) {
    if (rawName.includes('(')) {
      return rawName.split('(')[1].split(')')[0];
    } else if (rawName.includes('_')) {
      return rawName.split('_')[1];
    }

    return '';
  }


  public toBonagoRetailerBasic(data: any): BonagoRetailerBasic {
    const rawInformation = data['retailer']['Information'] as any[];
    const rawInformationDE = rawInformation.find(data => data['Language'] === 'DE');
    const rawInformationEN = rawInformation.find(data => data['Language'] === 'EN');

    const rawName = data['retailer']['Retailer']['Name'];

    const retailerId = data['retailer']['Retailer']['Id'];
    const retailerName = this.getRetailerTitle(rawName);

    return {
      _id: data['_id'],
      id: retailerId,
      rawName,
      title: retailerName,
      subtitle: this.getRetailerSubtitle(rawName),
      isNew: data['retailer']['Retailer']['IsNew'],
      information: !rawInformationDE ? undefined : rawInformationDE['Details'].reduce(
        (information: BonagoRetailerInformation, details: any) => {
          const type = details['Name'] as BonagoRetailerInformationType;

          information[type] = details['Value'];

          return information;
        },
        {} as BonagoRetailerInformation
      ),
      assets: data['retailer']['Assets'].reduce(
        (assets: BonagoRetailerAssets, asset: any) => {
          const type = asset['Type'] as BonagoRetailerLogoType;

          assets[type] = {
            url: asset['Url'],
            type,
            altText: asset['AltText'],
          } as BonagoRetailerLogo;

          return assets;
        },
        {} as BonagoRetailerAssets
      ),
      addresses: !rawInformationEN ? [] : this.toBonagoRetailerAddresses(
        retailerId,
        retailerName,
        rawInformationEN['Details'].find(
          (details: any) => {
            const type = details['Name'] as BonagoRetailerInformationType;

            return type === 'RetailerDescription';
          }
        )
      )
    };
  }

  public toBonagoRetailersBasic(data: any[]): BonagoRetailerBasic[] {
    const bonagoRetailersBasic = data.map(
      rawObject => this.toBonagoRetailerBasic(rawObject)
    );

    return bonagoRetailersBasic;
  }

  public toEmployeeAddress(data: any): EmployeeAddress {
    return {
      city: data['address']['ort'],
      email: this.made.user.emails[0],
      postcode: data['address']['plz'],
      street: data['address']['strasse'],
    };
  }

  public toBonagoRetailerAddresses(
    retailerId: number,
    retailerName: string,
    data?: any
  ) {
    let addresses: BonagoRetailerAddress[] = [];
    if (!data || !data['Value']) {
      return [];
    }

    data = data['Value'];

    if (data.includes('[{')) {
      let startIndex = data.indexOf('[');
      let endIndex = data.lastIndexOf(']') + 1;
      let addressesString = data.substring(startIndex, endIndex);
      addressesString = addressesString.replace(/`/g, '\"');
      addressesString = addressesString.replace(/,/g, ',');

      //possible endless loop
      while (addressesString.indexOf('}') != -1) {
        let startIndex = addressesString.indexOf('{');
        let endIndex = addressesString.indexOf('}');
        let addressString = addressesString.substring(startIndex + 1, endIndex);
        addressesString = addressesString.slice(endIndex + 1);
        let addressStringParts = addressString.split(',');
        let address = {
          retailerId,
          retailerName,
          street: JSON.parse(addressStringParts[0]),
          postcode: JSON.parse(addressStringParts[1]),
          city: JSON.parse(addressStringParts[2]),
          url: JSON.parse(addressStringParts[3]),
          lat: parseFloat(JSON.parse(addressStringParts[4])),
          lng: parseFloat(JSON.parse(addressStringParts[5]))
        } as BonagoRetailerAddress;
        addresses.push(address);
      }
    }

    return addresses;
  }
}
