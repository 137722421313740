import bonus_cancel_dialog_html from '../../../modules/administration/bonus_control_process/bonus_cancel_dialog.pug';
import bikeleasing_approve_dialog_html from '../../../modules/administration/bikeleasing_control_process/bikeleasing_approve.pug';
import edit_bonus_dialog_html from '../../../modules/administration/bonus_control_process/edit_bonus_dialog.pug';
import bonus_bikeleasing_cp_dialog_html from '../../../modules/administration/bikeleasing_control_process/bonus_bikeleasing_cp.pug';
import bonus_control_process_dialog_html from '../../../modules/administration/bonus_control_process/bonus_control_process_dialog.pug';
import bonus_history_dialog_html from '../../../modules/administration/bonus_history/bonus_history_dialog.pug';

import { EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS } from '../../../services/administrationService';
import {
  BIKELEASING_BONUS_NAMES,
  PC_BONUS_COMPONENT_NAMES
} from '../../../services/BonusService';
import {BONUS_PROJECT_BUDGET_FIELDS_BY_PROP} from "../../../services/bonus/default_bonus_project_budget_fields";
import {BONUS_PAYMENT_TYPES_BY_ID, BONUS_PAYMENT_TYPES_BY_NAME} from "../../../services/bonus/bonus_payment_type";
import {BONUS_STATES, BONUS_STATES_BY_ID, BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";
import {DEFAULT_BONUS_BUDGET_DISPLAY} from "../../../services/bonus/default_bonus_budget_display";

const EXCLUDED_COMPONENT_NAMES = ['pc_1', 'bike_1'];
const EMPTY_ARRAY = [];

const $inject = [
  '$scope',
  '$q',
  'lodash',
  '$state',
  'authenticationService',
  'BonusService',
  'employeeService',
  'userService',
  'dialogService',
  '$timeout',
  'Made',
  'mppService'
];
export default class BonusAdministrationController {
  constructor (
    $scope,
    $q,
    lodash,
    $state,
    authenticationService,
    BonusService,
    employeeService,
    userService,
    dialogService,
    $timeout,
    Made,
    mppService,
  ) {
    Object.assign(this, {
      $scope,
      $q,
      lodash,
      $state,
      authenticationService,
      BonusService,
      employeeService,
      userService,
      dialogService,
      $timeout,
      Made,
      mppService
    });

    this.user = this.Made.user;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');


    this.loading = {
      main: false,
      check_restbonuses_for_fid: false
    };
    this.$scope.filters = angular.copy(EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS);
    this.$scope.filters.bonus_states = {
      selected: [],
      options: angular.copy(BONUS_STATES)
    };
    this.employees = [];

    this.loading.main = true;
    this.init().then(()=>{
      const search = this.$state.params;
      if (search && search.employeeId ) {
        this.$scope.filters.id = search.employeeId;
        this.employee_id = this.$scope.filters.id;
        return this.filterBonusEmployees(true);
      }
    }).finally  (()=>{
      this.$timeout(()=>{
        this.loading.main = false;
      });
    });

  }

  async init() {
    this.BONUS_STATES_BY_NAME = BONUS_STATES_BY_NAME;
    this.BONUS_STATES_BY_ID = BONUS_STATES_BY_ID;
    this.BONUS_PROJECT_BUDGET_FIELDS_BY_PROP = BONUS_PROJECT_BUDGET_FIELDS_BY_PROP;
    this.DEFAULT_BONUS_BUDGET_DISPLAY = DEFAULT_BONUS_BUDGET_DISPLAY;
    this.BONUS_PAYMENT_TYPES_BY_NAME = BONUS_PAYMENT_TYPES_BY_NAME;
    this.BONUS_PAYMENT_TYPES_BY_ID = BONUS_PAYMENT_TYPES_BY_ID;
    this.BIKELEASING_BONUS_NAMES = BIKELEASING_BONUS_NAMES;
    this.HOTFIX_DELETE_USERS = [66279, 66570, 66285, 83507, 88569, 134897, 113170, 134897, 88569]; // TODO: VERY IMPORTANT TO REMOVE!

    this.forms = {};
    this.display_filters = {
      to_show_declined : false
    };

    this.pcLeasingBlockedList = [];


    await this.getBonusComponentsMap();
  }

  goToOTRS(employeeData){
    this.employeeService.goToOTRS(employeeData.id);
  }

  goToNorbert(employeeData){
    this.employeeService.goToNorbert(employeeData.id);
  }

  editEmployee(id) {
    this.dialogService
    .employeeCreate(id)
    .then(() => {
      this.filterBonusEmployees();
    });
  }

  fix_future_data() {
    this.loading.fix_future_data = true;
    this.BonusService.fix_future_data().then((report)=>{
      this.future_data_report = report;
      this.$timeout(()=>{
        this.loading.fix_future_data = false;
      });
    });
  }

  check_restbonuses_for_fid(for_upcoming) {
    this.loading.check_restbonuses_for_fid = true;
    this.BonusService.checkRestbonusesForFid({for_upcoming: for_upcoming}).then((report)=>{
      this.problematic_users = report['users'];
      this.overspend_amount = report['sum'];
      this.$timeout(()=>{
        this.loading.check_restbonuses_for_fid = false;
      });
    });
  }


  calculateBudgetDisplay( employee_bonus_budget, display, bonus_payment_type_id){
    return this.BonusService.calculateBudgetDisplay( employee_bonus_budget, display, bonus_payment_type_id);
  }

  filterBonusEmployees (expand = false) {

    let filters = this.formatFilters();
    if (this.areFiltersEmpty(filters)) {
      return;
    }

    this.loading.main = true;
    return this.employeeService.filterBonusEmployees(filters)
      .then(employees => {
        this.employees = employees;
        this.employee_objects = {};
        this.employees.forEach( (employee) => { this.employee_objects[employee.id] = {}; });
        this.groupEmployeesByCustomerId();
        this.indexCustomers();
        this.getPcLeasingBlockedEmployees(employees);

        this.$timeout(()=>{
          this.loading.main = false;
        });
      });
  }

  goToNEO(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.neo',
      'params': {employeeId: employeeId}
    });
  }
  goToPCleasing(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.mpp.edit',
      'params': {employeeId: employeeId}
    });
  }
  groupEmployeesByCustomerId () {
    this.grouped_employees = this.employees.reduce((r, a) => {
      r[a.customer_id] = [...r[a.customer_id] || [], a];
      return r;
    }, {});
  }

  indexCustomers () {
    this.customers_by_id = this.employees.reduce((acc, curr) => {
      acc[curr.customer_id] = {
        name: curr.customer_name,
        id: curr.customer_id
      };
      return acc;
    }, {});
  }

  formatFilters () {
    let filters = {
      id: this.$scope.filters.id,
      name: this.$scope.filters.name,
      personal_number: this.$scope.filters.personal_number,
      made_status: this.$scope.filters.made_status.selected.map(status => status.id),
      order_by_name: this.$scope.filters.order_by_name == true ? 'DESC' : 'ASC',
      enable_timestamp: false,
      bonus_state: this.$scope.filters.bonus_states.selected.map(state => state.id),
      bonus_begin: this.$scope.filters.bonus_begin || null,
      component_name: this.$scope.filters.component_name.selected || []
    };

    //customer id/name logic TODO: refactor to avoid repetiotions with functionality in EmployeeListController;
    let is_cust_id = !isNaN(this.$scope.filters.custId);
    if (is_cust_id) {
      filters.cust_id = this.$scope.filters.custId;
      filters.customer_name = '';
    } else {

      filters.customer_name = this.$scope.filters.custId;
      filters.cust_id = '';
    }

    return filters;
  }


  areFiltersEmpty(filters) {
      let user_entered_filter_keys = ['id', 'personal_number', 'name', 'made_status.selected', 'bonus_begin', 'bonus_state', 'cust_id', 'customer_name'];
      let unused_filter_keys = ['last_login', 'component_name']; // THIS IS JUST INFORMATIVE FOR BETTER CODE COMPREHENSION
      let are_filters_empty = true;

      user_entered_filter_keys.forEach((key) => {
        if (this.lodash.get(filters, key) !== null && (this.lodash.get(filters, key, '').length > 0)) {
          are_filters_empty = false;
        }
      });

      return are_filters_empty;
    }

    triggerSearch(e) {
      if (e && e.keyCode === 13) {
        e.preventDefault();
        this.filterBonusEmployees();
      }
    }
  loadEmployeeData (employeeData, employee, toggle_content_showable) {
    if (toggle_content_showable) {
      return;
    }
    this.$timeout(()=>{
      this.loading.main = false;
    });

    return this.BonusService.getBonusProjectsDisplayForUser(employeeData.id, true)
      .then(async (bonus_projects)=> {
        employeeData['has_active_bonus_projects'] = !!bonus_projects.length;

        if (bonus_projects.length === 0) {
          return;
        }

        employeeData['bonus_projects_for_employee'] = bonus_projects;
        employeeData['bonus_projects'] = {};

        employeeData['bonus_projects_for_employee'].forEach((bonus_project) => {
          bonus_project['is_visible'] = false;
        });

        this.$timeout(()=>{
          this.loading.main = false;
        });
      });
  }

  loadEmployeeBonusProjectData(employeeData, employee, bonus_project) {
    this.$timeout(()=>{
      bonus_project['is_loading'] = true;
    });

    let promises = [];
    employeeData['bonus_projects'][bonus_project._id] = {};
    let bonus_project_id = bonus_project._id;

    employeeData['bonus_projects'][bonus_project_id]['name'] = bonus_project['name'];
    employeeData['bonus_projects'][bonus_project_id]['dates'] = bonus_project['dates'];

    employeeData['bonus_projects'][bonus_project['_id']].bonus_project_id = bonus_project_id;

    promises.push(
      this.BonusService.getUserFidInfoBBG(employeeData.id).then(bbg => {
        employeeData['bonus_projects'][bonus_project_id].bbg = bbg;
      })
    );

    promises.push(this.userService.getMadeUserByValuenetId(employeeData.id, Date.now()));

    promises.push(
      this.BonusService.getBonusesForEmployee({
        customer_id: employeeData.customer_id,
        bonus_project_id: bonus_project_id,
        to_attach_administration_validations: true,
        employee_id:employeeData.id
      }).then(bonuses => {
        employeeData['bonus_projects'][bonus_project_id].bonuses = bonuses;
        employeeData['bonus_projects'][bonus_project_id].unavailable_bonuses = this.filterUnavailableBonuses(bonuses);
        employeeData['bonus_projects'][bonus_project_id].bikeleasing_available = this.bikeleasingAvailable(bonuses);
      })
    );

    promises.push(
      this.BonusService.getEmployeeBonusBudget(employeeData.id, bonus_project_id)
        .then((bonus_usage) => {
          employeeData['bonus_projects'][bonus_project_id].bonus_budget = bonus_usage;
        })
    );

    promises.push(
      this.BonusService.calculateBikeleasingLimits(employeeData.id, bonus_project_id, employeeData.customer_id)
        .then(limits => {
          employeeData['bonus_projects'][bonus_project_id].limits = limits;
          employeeData['bonus_projects'][bonus_project_id].bikeleasing_permitted = limits.is_overspending_allowed || limits.is_user_rate_valid;
        }).catch((e)=>{
          employeeData['bonus_projects'][bonus_project_id].bikeleasing_permitted = false;
        })
    );

    return this.$q.all(promises)
      .then(() => {
        this.$timeout(()=>{
          bonus_project['is_loading'] = false;
        });
    });
  }


  getComponentDisplayStartDate(bonus){
    return this.BonusService.getComponentDisplayStartDate(bonus);
  }

  getComponentDisplayEndDate(bonus) {
    return this.BonusService.getComponentDisplayEndDate(bonus);
  }

  getComponentDisplayCheckoutDate(bonus) {
    return this.BonusService.getComponentDisplayCheckoutDate(bonus);
  }

  filterUnavailableBonuses (bonuses) {
    let unavailable_bonuses = [];
    console.log("bonuses", bonuses);
    bonuses.forEach(bonus => {
      if (bonus.state !== this.BONUS_STATES_BY_NAME['available']['id']) {
        unavailable_bonuses.push(bonus);
      }

      if (this.BonusService.hasPreviousCheckouts(bonus)) {
        const previous_checkouts = this.BonusService.getPreviousCheckouts(bonus);
        for (const previous_checkout_id in previous_checkouts) {
          unavailable_bonuses.push(previous_checkouts[previous_checkout_id]);
        }
      }

      if (this.BonusService.hasFutureCheckouts(bonus)) {
        const future_checkouts = this.BonusService.getFutureCheckouts(bonus);
        for (const future_checkouts_id in future_checkouts) {
          unavailable_bonuses.push(future_checkouts[future_checkouts_id]);
        }
      }
    });

    unavailable_bonuses = this.lodash.sortBy(unavailable_bonuses, [
      (bonus) => this.BonusService.getBonusComponentName(bonus)],
      (bonus) => bonus.dates.bonus_start_date
    );

    return unavailable_bonuses;
  }

  getUnavailableBonuses(bonus_project) {
    if (!bonus_project || !bonus_project.unavailable_bonuses) {
      return EMPTY_ARRAY;
    }

    if (this.display_filters.to_show_declined) {
      return bonus_project.unavailable_bonuses
    } else {
      return bonus_project.unavailable_bonuses.filter(bonus => !this.BonusService.isComponentDeclined(bonus));
    }
  }

  openCancelBonus(employee_id, bonus, bonus_project_id){
    this.dialogService.ngDialog.openConfirm({
      template: bonus_cancel_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusCancelDialogController',
      data: {
        employee_id: employee_id,
        bonus: bonus,
        bonus_project_id: bonus_project_id
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterBonusEmployees();
    }).catch(() => {
      this.filterBonusEmployees();
    });
  }

  toggleBonusProjectShow(employeeData, employee, bonus_project){
    bonus_project['is_visible']= !bonus_project['is_visible'];

    if (bonus_project['is_visible']) {
      return this.loadEmployeeBonusProjectData(employeeData, employee, bonus_project);
    }
  }


  canCancelBonus(bonus){
    if (!this.areOperationsAllowed(bonus)) {
      return false;
    }
    const componentName = this.BonusService.getBonusComponentName(bonus);
    const newComponentsForPreend = [
      'sachbezug',
      'sachbezug_pluxee',
      'sachbezug_profit',
      'internet',
      'festnetz',
      'essensschecks_pst',
      'essensschecks_frei',
      'entfernung',
      'handy'
    ];
    //https://vsp.atlassian.net/browse/P92VN-1784
    return this.BonusService.isComponentApproved(bonus.state)  && (
      this.BIKELEASING_BONUS_NAMES.includes(componentName) ||
      newComponentsForPreend.includes(componentName)
    );
  }

  canEditBonus(bonus){
    if (this.iAmSpecialAdvisor && this.BONUS_STATES_BY_NAME['declined']['id'] !== bonus.state) {
      return true;
    } else if (!this.areOperationsAllowed(bonus)) {
      return false;
    } else {
      let is_approved = this.BONUS_STATES_BY_NAME['approved']['id'] === bonus.state;
      let has_started = this.getComponentDisplayStartDate(bonus) <= new Date();
      let is_declined = this.BONUS_STATES_BY_NAME['declined']['id'] === bonus.state;
      let must_not_be_touched = (is_approved && has_started) || is_declined;
      return !must_not_be_touched;
    }
  }

  openBikeleasingApproveDialog(employee_id, bonus_project_id, employeeData, bonus) {
    this.dialogService.ngDialog.openConfirm({
      template: bikeleasing_approve_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusBikeleasingApproveController',
      data: {
        employee_id: employee_id,
        bonus_project_id: bonus_project_id,
        bonus_id: bonus._id
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterBonusEmployees();
    }).catch(() => {
      this.filterBonusEmployees();
    });
  }

  bikeleasingAvailable(bonuses){
    let bikeleasing_available = false;
    bonuses.forEach((bonus) => {
      if (!this.BonusService.isComponentUsed(bonus) && this.BIKELEASING_BONUS_NAMES.includes(this.BonusService.getBonusComponentName(bonus))) {
        bikeleasing_available = true;
        return;
      }
    });
    return bikeleasing_available;
  }

  getBonusComponentsMap () {
    return this.BonusService.getAvailableBonusComponents().then((res) => {
      this.BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {});


      this.BONUS_COMPONENTS_DISPLAY = res
      .filter(component => !EXCLUDED_COMPONENT_NAMES.includes(component['name']))
      .map( component => {
        let display = component['display'];
        display['name'] = component['name'];
        return display;
      });


      this.$scope.filters.component_name = {
        selected: [],
        options: angular.copy(this.BONUS_COMPONENTS_DISPLAY)
      }
    });
  }


  openEditBonus(employee_id, bonus, bonus_project_id){
    this.dialogService.ngDialog.openConfirm({
      template: edit_bonus_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusEditController',
      data: {
        employee_id: employee_id,
        bonus: bonus,
        bonus_project_id: bonus_project_id,
        to_allow_same_day_change: this.HOTFIX_DELETE_USERS.includes(this.Made.user.valuenet_id)
      },
      controllerAs: '$editCtrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterBonusEmployees();
    }).catch(() => {
      this.filterBonusEmployees();
    });
  }

  openBikeleasingControlProcess (employee_id, customer_id, bonus_project_id) {
    this.dialogService.ngDialog.openConfirm({
      template: bonus_bikeleasing_cp_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusBikeleasingControlProcessController',
      data: {
        employee_id: employee_id,
        customer_id: customer_id,
        bonus_project_id: bonus_project_id
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterBonusEmployees();
    }).catch(() => {
      this.filterBonusEmployees();
    });
  }

  startControlProcess (employee_id, customer_id, bonus_id, employeeData, employee) {
    this.dialogService.ngDialog.openConfirm({
      template: bonus_control_process_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusControlProcessController',
      data: {
        employee_id: employee_id,
        customer_id: customer_id,
        bonus_id: bonus_id
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterBonusEmployees();
    }).catch(() => {
      this.filterBonusEmployees();
    });
  }

  openBonusHistoryDialog(bonus) {
    this.dialogService.ngDialog.openConfirm({
      template: bonus_history_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusHistoryDialogController',
      data: {
        bonus: bonus
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }

  areOperationsAllowed(bonus) {
    if (!bonus) {
      return false;
    }

    return this.lodash.get(bonus, 'validations.administration.overall', false);
  }

  showControlProcessButton(bonus) {

    if (!this.areOperationsAllowed(bonus)) {
      return false;
    }

    let isCashbackBonus = this.BonusService.isBonusCashback(bonus);
    if (isCashbackBonus) {
      return false;
    }

    if (bonus.state === this.BONUS_STATES_BY_NAME['first_check']['id'] || bonus.state === this.BONUS_STATES_BY_NAME['control_start']['id']) {
      return true;
    }

    return false;
  }

  become(employeeData) {

    this.loading.main = true;
    const context_path = '/administration/bonus?employeeId=' + employeeData.id;

    this.dialogService
      .question(`Achtung, der Nutzer wird über diesen Vorgang informiert.
        Wollen Sie wirklich in ${employeeData.name}, ${employeeData.vorname}
        (${employeeData.id}) schlüpfen?`)
      .then(() => this.userService.enterProxy(employeeData.id, context_path))
      .catch(() => this.loading.main = false);

  }

  showBikeleasingApprovalButton(bonus) {
    if (!this.areOperationsAllowed(bonus)) {
      return false;
    }

    if (bonus.state === this.BONUS_STATES_BY_NAME['approval_approved']['id'] && this.BIKELEASING_BONUS_NAMES.includes(this.BonusService.getBonusComponentName(bonus))) {
      return true;
    }
    return false;
  }

  getPcLeasingBlockedEmployees(employees) {
    for(const employee of employees) {
      this.employeeService.isPcBlockedForEmployee(employee.id)
      .then(isBlocked => {
        if(isBlocked) {
          this.pcLeasingBlockedList.push(employee.id)
        }
      })
    }
  }

  isEmployeeBlockedForPcLeasing(employeeId) {
    return this.pcLeasingBlockedList.includes(employeeId);
  }

}
BonusAdministrationController.$inject = $inject;
