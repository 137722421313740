const $inject = [
  'Made',
  'authenticationService',
  'employeeService',
  'dialogService',
  'customerService',
  'userService',
  '$timeout',
  '$scope',
  '$location',
  'NotificationService'
];
export default class ActivationController {
  constructor(
    Made,
    authenticationService,
    employeeService,
    dialogService,
    customerService,
    userService,
    $timeout,
    $scope,
    $location,
    NotificationService
  ) {
    Object.assign(this, {
      Made,
      authenticationService,
      employeeService,
      customerService,
      dialogService,
      userService,
      $timeout,
      $scope,
      $location,
      NotificationService
    });


    this.employee = null;
    this.enteredCode = null;
    this.isActivationCodeValid = {
      error: undefined,
      message: ''
    };

    this.isValidationResult = {
      isValid: false,
      error: undefined,
      message: ''
    };


    this.forms = {
      activationPersonalForm: {},
      activationForm: {},
    };

    $scope.canContinue = () => {
      return !!this.enteredCode;
    }

  }

  async validateActivationCredentials() {
    if (this.enteredCode) {
      this.toc = false;

      let activationCredentials = {
        code: this.enteredCode,
        pin: this.personalNumber
      };

      let areActivationCredentialsValid = await this.employeeService.validateActivationCredentials(
        activationCredentials
      );

      this.$timeout(() => {
        this.isActivationCodeValid = areActivationCredentialsValid;

        if (this.isActivationCodeValid.error) {
          this.setValidity('activationPersonalForm' ,'personalNumber', 'wrong', false);
          // this.dialogService.error({
          //   title: 'Ein Problem mit der Aktivierung ist aufgetreten',
          //   text: areActivationCredentialsValid.message
          // });
        }
      }, 0);
    }
  }

  handlePersonalNumberChange() {
    if (this.forms.activationPersonalForm.$error.wrong) {
      this.setValidity('activationPersonalForm', 'personalNumber', 'wrong', true);
    }
  }

  toggleRead(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.toc) {
      this.showToc();
    } else {
      this.toc = !this.toc;
    }
  }

  showToc() {
    this.dialogService.registrationToc(this.$scope.configuration).then((result) => {
      if (result) {
        this.readToc = true;
        this.toc = true
      }
    });
  }

  async validatePersonalNumber() {
    let validationResult = await this.Made.request(
      'rpc://valuenetdb/employee/get_activation_validation_result',
      {
        activation_code: this.enteredCodePart1 + this.enteredCodePart2,
        personal_number: this.personalNumber
      }
    );

    this.$timeout(() => {
      this.isValidationResult.error = validationResult.error;
      this.isValidationResult.message = validationResult.message;

      if (this.isValidationResult.error) {
        this.setValidity('activationPersonalForm' ,'personalNumber', 'wrong', false);
      } else {
        this.setValidity('activationPersonalForm', 'personalNumber', 'wrong', true);
        this.isValidationResult.isValid = true;
      }
    }, 0);
  }

  handleUserActivation() {
    const activationData = {
      personal_number: this.personalNumber,
      act_code: this.enteredCode,
      email: this.email,
      term_cond: this.readToc,
      firstname: this.firstname,
      lastname: this.lastname
    };

    this.authenticationService.activateUserWithActivationCode(
      activationData
    ).then(employee => {
      if (!employee) {
        location.reload();
        throw 'activateUserWithActivationCode need to be checked';
      }

      this.userService.inviteActivated(
        employee.valuenet_id,
        activationData.act_code
      ).then(result => {
        if (!result) {
          location.reload();
        }
        this.userService.externalAcceptToc(
          0,
          employee.valuenet_id
        ).then(response => {
          if (!response) {
            location.reload();
          }
          this.success = true;
        });
      });
    },(err) => {
      let error = err.error;
      let message = error.message.text;

      if (error) {
        const flag = error.message.flag;
        switch (flag) {
          case 'emailNotUnique':
            this.setValidity('activationForm', 'activation.email', 'unique', false);
            break;
          case 'emailNotExist':
            this.setValidity('activationForm', 'activation.email', 'exist', false);
            break;
          case 'domainIsIncorrect':
            this.setValidity('activationForm', 'activation.email', 'domain', false);
            message = this.getDomainErrorMessage();
            break;
        }
        this.NotificationService.error(message);
        this.error = true;
      }
    });
  }

  async getPattern() {
    if (this.isChecksumCorrect) {
      this.employee = await this.employeeService.getByActivationCode(this.enteredCode);

      if (this.employee) {
        this.$scope.isEmployeeTemporary = this.employeeService.isEmployeeTemporary(this.employee);
        this.$scope.isEmployeeTest = this.employeeService.isEmployeeTest(this.employee);
        this.email = this.employee['emails'][0];
        this.firstname = this.employee['firstname'];
        this.lastname = this.employee['lastname'];

        let configuration = await this.customerService.getConfigurationByEmployeeId(this.employee.valuenet_id);

        this.$timeout(() => {
          this.$scope.employee = this.employee;
          this.$scope.configuration = configuration;
          this.$scope.pattern = configuration.personal_number_config;
        }, 0);
      } else {
        // TODO: handle wrong activation code
      }
    }
  }

  getPersonalNumberPattern() {
    if (this.$scope.isEmployeeTemporary || this.$scope.isEmployeeTest) {
      console.log('Temporary')
      return null;
    }
    return this.$scope.pattern && this.$scope.pattern.is_enabled ? this.$scope.pattern.syntax : null
  }

  onEnterCode() {
    if (this.enteredCodePart1 && this.enteredCodePart2 && this.enteredCodeChecksum) {
      this.enteredCode = this.enteredCodePart1 + this.enteredCodePart2;
      this.isChecksumCorrect = this.computeCheckSum(this.enteredCode) === parseInt(this.enteredCodeChecksum);
    } else {
      this.enteredCode = null;
      this.errorCode = null;
    }
  }

  computeCheckSum(code) {
    let sum = 0;
    const codeString = code.split('');

    codeString.forEach(codeChar => sum += codeChar.charCodeAt(0));

    return Math.ceil((sum * 2.35) % 9);
  }

  handleEmailInputChange() {
    this.resetUniqueEmail();
    this.checkEmailUniqueness();
    this.checkEmailDomain();
  }

  async checkEmailUniqueness() {
    const isEmailAvailable = !!this.email;

    if (isEmailAvailable) {
      try {
        const isEmailUnique = await this.employeeService.checkEmployeeByEmail(this.email);
        this.setValidity('activationForm', 'activation.email', 'unique', !isEmailUnique.exists);
      } catch (err) {
        this.setValidity('activationForm', 'activation.email', 'email', false);
        this.error = true;
      }
    } else {
      this.setValidity('activationForm', 'activation.email', 'email', false);
    }
  }

  async checkEmailDomain() {
    const email = this.email;
    const config = this.$scope.configuration.domains_config;

    if (email === undefined) {
      this.setValidity('activationForm', 'activation.email', 'email', false);
      return false;
    }

    try {
      const isBaseOnDomain = await this.customerService.isEmployeeEmailDomainValid(email, config);
      if (isBaseOnDomain) {
        this.setValidity('activationForm', 'activation.email', 'domain', true);
      } else {
        this.setValidity('activationForm', 'activation.email', 'domain', false);
      }
    } catch (err) {
      this.setValidity('activationForm', 'activation.email', 'email', false);
      this.error = true;
    }
  }

  getDomainErrorMessage(){
    return this.customerService.getCustomerDomainErrorMessage(this.$scope.configuration.domains_config);
  }

  resetUniqueEmail() {
    this.setValidity('activationForm', 'activation.email', 'unique', true);
  }

  setValidity(formName, param, paramName, paramValue) {
    this.forms[formName][param].$setValidity(paramName, paramValue);
  }

  canPassPersonalnumber() {
    return this.forms.activationPersonalForm.$valid &&
      this.$scope.canContinue()
  }

  handleNameChange(part) {
    const value = this.forms.activationForm['activation.' + part].$viewValue;
    this.setValidity('activationForm', 'activation.' + part, 'required', !!value.length);
  }

}
ActivationController.$inject = $inject;
