import {at} from "lodash";

const $inject = [
  '$scope',
  'BonusService',
  '$timeout',
  'moment',
  'NotificationService'
];
export default class BonusCancelDialogController {
  constructor(
    $scope,
    BonusService,
    $timeout,
    moment,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      BonusService,
      $timeout,
      moment,
      NotificationService
    });


    this.loading = {
      main: false
    };

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.forms = {
      cancel_form: {}
    };

    this.models = {
      cancel: {
        cancel_comment: '',
        new_end_date: undefined
      }
    };

    const currentDate = new Date();
    this.bonus = this.$scope.ngDialogData.bonus;
    this.bonusEndDateDatePicker = new Date(this.bonus.dates.bonus_end_date * 1000);
    this.bonusStartDate = new Date(this.bonus.dates.bonus_start_date * 1000);
    // angularrjs datepicker shit...
    const bonusStartDateEndParts = this.bonusStartDate.toLocaleDateString().split('/')
    const endDateStartDateEndParts = this.bonusEndDateDatePicker.toLocaleDateString().split('/')
    const dateTimePreend = new Date(
      Number(endDateStartDateEndParts[2]),
      Number(endDateStartDateEndParts[1]),
      0
    );
    this.datePickerPreendLimitDate =  dateTimePreend.toLocaleDateString().replaceAll('/', '-');
    console.log("limit", this.datePickerPreendLimitDate);
    function getLastDaysOfMonths(startFromMonth = 1, endMonth = 12, yearFrom, yearTo) {
      let dates = [];
      let sFm = Number(startFromMonth);
      let eFm = Number(endMonth);
      let yearF = Number(yearFrom);
      let yearT = Number(yearTo);

      if (yearF > yearT) {
        throw new Error("Invalid dates");
      }

      let newYearStart = false;
      let curDate = new Date();
      let curM = curDate.getMonth() + 1;
      let curY = curDate.getFullYear();

      while (yearF <= yearTo) {
        let lastYear = yearF === yearT;

        for (let i = !newYearStart ? sFm : 1; i <= (lastYear ? eFm : 12); i++) {
          let lastDay = new Date(yearF, i, 0).getDate();

          if (yearF < curY || (yearF === curY && i < curM)) {
            continue
          }

          let formattedDate = `${yearF}/${i}/${lastDay}`;
          dates.push(formattedDate);
        }

        yearF++;
        newYearStart = true;
      }

      return dates;
    }

    console.log(this.datePickerPreendLimitDate)

    currentDate.setHours(0, 0, 0, 0);
    this.bonusStartDate.setHours(0, 0, 0, 0);
    let starFromMonth = this.bonusStartDate <= currentDate ? currentDate.getMonth() + 1 : bonusStartDateEndParts[1]
    let endDateMonth = dateTimePreend.getMonth() + 1;
    this.enabledDates = JSON.stringify(getLastDaysOfMonths(
      starFromMonth,
      endDateMonth,
      bonusStartDateEndParts[2],
      endDateStartDateEndParts[2])
    );
    console.log("dates", this.enabledDates);
    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  canCancelForm() {
    return this.forms.cancel_form.$valid;
  }

  cancelBonus() {
    if (!this.canCancelForm()) {
      return;
    }

    this.$timeout(() => {
      this.loading.cancel = true;
    });


    return this.BonusService.administrationBonusCancel({
      bonus_id: this.bonus._id,
      comment: this.models.cancel.cancel_comment,
      new_end_date: this.models.cancel.new_end_date
    })
      .then(() => {
        this.$scope.confirm();
        this.NotificationService.message('OK');
      })
      .catch(e => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.cancel = false;
        });
      });
  }

}
BonusCancelDialogController.$inject = $inject;
