import {Component, Inject, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface ConfirmationDialogData {
  title: string,
  subtitle: string,
  withDeclineReason: boolean,
  noButton: string,
  yesButton: string,
  reasonLabel: string
}

@Component({
  selector: 'vn-dticket-order-cancel-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  subtitle: string|null = null;
  withDeclineReason: boolean = false;
  noButton: string = 'Abbrechen';
  yesButton: string = 'Bestätigen';
  reasonLabel: string = 'Dieser Kommentar wird dem Mitarbeiter übermittelt.';

  declineReasonForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ConfirmationDialogData,
    private formBuilder: FormBuilder,
  ) {
    this.title = this.dialogData.title;
    this.withDeclineReason = this.dialogData.withDeclineReason;
    this.subtitle = this.dialogData.subtitle;
    this.noButton = this.dialogData.noButton || this.noButton;
    this.yesButton = this.dialogData.yesButton || this.yesButton;
    this.reasonLabel = this.dialogData.reasonLabel || this.reasonLabel;

    if (this.withDeclineReason) {
      this.declineReasonForm = this.formBuilder.group({
        declineReason: this.formBuilder.control(null, [Validators.required]),
      });
    }
  }

  ngOnInit(): void {
  }

  userAction(action: 'yes'|'with_reason') {

    const userDecisionData = {
      decision: action,
      data: null
    }

    if(this.withDeclineReason) {
      if (!this.declineReasonForm.valid) {
        return;
      }
      const { declineReason } = this.declineReasonForm.value;
      userDecisionData['data'] = declineReason;
      userDecisionData['decision'] = 'with_reason';
    }

    this.dialogRef.close(userDecisionData);

  }

}
