const $inject = [
  "$q",
  "Made"
];

export default class UtilityService {

  constructor($q, Made) {
    this.$q = $q;
    this.Made = Made;
  }
}

UtilityService.$inject = $inject;
