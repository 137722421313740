const $inject = [
  '$window',
  '$scope',
  '$timeout',
  '$http',
  'fileService',
  'PDFJS',
  'Made',
  'userService',
  'VSP_CONSTANTS',
  'NotificationService',
  'DigitalSigningService',
  'DigitalSignatureYouSignServiceV3'
];
export default class MissingDocumentDialogController {
  constructor(
    $window,
    $scope,
    $timeout,
    $http,
    fileService,
    PDFJS,
    Made,
    userService,
    VSP_CONSTANTS,
    NotificationService,
    DigitalSigningService,
    DigitalSignatureYouSignServiceV3
  ) {
    Object.assign(this, {
      $window,
      $scope,
      $timeout,
      $http,
      fileService,
      PDFJS,
      Made,
      userService,
      VSP_CONSTANTS,
      NotificationService,
      DigitalSigningService,
      DigitalSignatureYouSignServiceV3
    });

    this.pdfGeneratorUrl = this.$scope.ngDialogData.pdfGeneratorUrl;
    this.employeeId = this.$scope.ngDialogData.employeeId;
    this.resourceId = this.$scope.ngDialogData.resourceId;
    this.customerId = this.$scope.ngDialogData.customerId;
    this.comp_type = this.$scope.ngDialogData.comp_type;
    this.canUseDigitalSigniture = this.$scope.ngDialogData.canUseDigitalSigniture;

    this.PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

    this.showDownloadButton = true;
    if (this.canUseDigitalSigniture) {
      this.showDownloadButton = this.$scope.ngDialogData.canSignManually;
    }

    this.is_bo_request = this.comp_type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BO_REQUEST;

    this.signUrl = null;
    this.loading = false;
    this.digitalSigniture = false;

    this.init();

  }

  async init() {
    if (this.is_bo_request  ) {
      let request = {
        method: 'GET',
        responseType: 'blob',
        url: this.pdfGeneratorUrl
      };

      this.$http(request).then(async (res) => {
        this.contractBLOB = res.data;

        this.loadingTask = this.PDFJS.getDocument({
          data: new Uint8Array(await res.data.arrayBuffer())
        });

        this.showPDF();
      });
    } else {

      this.Made.getDocument({
        file_url: this.pdfGeneratorUrl,
        type: 'from_url'
      }).then(async (res) => {
        this.contractBLOB = res.data;

        this.loadingTask = this.PDFJS.getDocument({
          data: new Uint8Array(await res.data.arrayBuffer())
        });

        this.showPDF();
      });
    }

  }

  async showPDF() {
    this.__loading(true);

    let that = this;

    this.loadingTask.promise.then(function(pdf) {

      that.$timeout(() => {
        let viewer = document.getElementById('pdf-viewer');


        for(let page = 1; page <= pdf.numPages; page++) {
          pdf.getPage(page).then(function(page) {

            let scale = 1.5;
            let viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            let canvas = document.createElement('canvas');
            canvas.className = 'pdf-page-canvas';
            viewer.appendChild(canvas);

            let context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            let renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            let renderTask = page.render(renderContext);

            renderTask.promise.then(function () {
              console.log('Page rendered');
            });
          });
        }
      });

    }, (reason) => {
      // PDF loading error
      // rarely to happen, but if it we still have the digital signiture
      this.NotificationService.error("Something went wrong during PDF visualization");
    }).finally(() => {
      this.__loading(false);
    });
  }

  __loading(val) {
    this.$timeout(() => this.loading = val);
  }

  downloadPDF() {
    this.fileService.downloadFile(this.contractBLOB, 'contract.pdf', 'application/pdf');
    this.$scope.confirm({action: 'download'});
  }

  viewPDF() {
    this.digitalSigniture = false;
    this.showPDF();
  }

  startUserlane() {
    Userlane('start', '83702');
    }

  async sign() {
    if (this.signUrl) {
      this.digitalSigniture = true;
    } else {
      this.__loading(true);

      let res = null;

      try {
        const externalDocumentUrl = this.comp_type === this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BO_REQUEST ? null : this.pdfGeneratorUrl
        res = await this.DigitalSignatureYouSignServiceV3.initiate(
          this.employeeId,
          externalDocumentUrl,
          this.resourceId,
          this.comp_type
        );
      } catch (e) {
        this.NotificationService.error("Something went wrong during the PDF Digital Signing");
        this.$scope.confirm({action: 'reload'});
      }

      if (res) {
        this.$timeout(() => {

          if (!res.is_signed) {
            this.signUrl = res.url;
            this.digitalSigniture = true;
            this.__loading(false);

          } else {
            this.NotificationService.message("Der Signaturvorgang für dieses Dokument ist bereits abgeschlossen.");
            this.$scope.confirm({action: 'reload'});
          }
        });

      }
    }
  }

}

MissingDocumentDialogController.$inject = $inject;
