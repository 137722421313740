import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { BackLinkComponent } from './components/checkout/back-link/back-link.component';
import { DropzoneComponent } from './components/form-mixins/dropzone/dropzone.component';
import { InputComponent } from './components/form-mixins/input/input.component';
import { SelectComponent } from './components/form-mixins/select/select.component';
import { DateComponent } from './components/form-mixins/date/date.component';
import { DateRangeComponent } from './components/form-mixins/date-range/date-range.component';
import { StateComponent } from './components/form-mixins/state/state.component';

import { LoaderDirective } from './directives/loader/loader.component';
import { RandomAnimateDirective } from './directives/random-animate/random-animate.directive';
import { AlertService } from "./services/alert.service";
import { PopupService } from "./services/popup.service";
import { TocCheckboxComponent } from './components/form-mixins/toc-checkbox/toc-checkbox.component';
import { TocCheckboxDialogComponent } from './components/form-mixins/toc-checkbox-dialog/toc-checkbox-dialog.component';

import { QuillModule } from "ngx-quill";
import { MatblurLoadingComponent } from './components/matblur-loading/matblur-loading.component';
import { HoneypotFormComponent } from './components/form-mixins/honeypot/honeypot-form.component';
import { ConsentDialogComponent } from './components/consent-dialog/consent-dialog.component';
import { CompanyEmailCheckboxComponent } from "./components/form-mixins/company-email-checkbox/company-email-checkbox.component";
import { CheckboxComponent } from './components/form-mixins/checkbox/checkbox.component';
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import { NewsletterDialogComponent } from './components/newsletter-dialog/newsletter-dialog.component';
import {MatRadioModule} from "@angular/material/radio";

@NgModule({
  declarations: [
    // ===================== COMPONENTS =====================
    BackLinkComponent,
    DropzoneComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    DateRangeComponent,
    StateComponent,
    TocCheckboxComponent,
    TocCheckboxDialogComponent,
    HoneypotFormComponent,
    CompanyEmailCheckboxComponent,
    CheckboxComponent,
    // ===================== DIRECTIVES =====================
    LoaderDirective,
    RandomAnimateDirective,
    MatblurLoadingComponent,
    ConsentDialogComponent,
    ConfirmationDialogComponent,
    NewsletterDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MatRadioModule,
    QuillModule.forRoot()
  ],
  providers: [
    AlertService,
    PopupService,
    DatePipe,
    CurrencyPipe
  ],
  exports: [
    // ===================== MODULES =====================
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    GoogleMapsModule,
    QuillModule,
    // ===================== COMPONENTS =====================
    BackLinkComponent,
    DropzoneComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    DateRangeComponent,
    StateComponent,
    TocCheckboxComponent,
    TocCheckboxDialogComponent,
    HoneypotFormComponent,
    CompanyEmailCheckboxComponent,
    CheckboxComponent,
    // ===================== DIRECTIVES =====================
    LoaderDirective,
    RandomAnimateDirective,
    MatblurLoadingComponent,
    ConsentDialogComponent,
    ConfirmationDialogComponent,
  ]
})

export class SharedModule {}
