import {NewsletterDialogComponent} from "../../app/shared/components/newsletter-dialog/newsletter-dialog.component";

const $inject = [
  'Made',
  'MatDialogService',
  'userService'
];

export default class mailService {

  constructor(Made, MatDialogService, userService) {

    Object.assign(this, {
      Made,
      MatDialogService,
      userService
    });

  }

  send(tos, subject, templatename, context = {}) {

    if ('string' === typeof tos) {
      tos = [
        tos
      ];
    }

    return this.Made.request('rpc://mail/send_template', {
      tos,
      subject,
      templatename,
      context
    });
  }

  updateContact(valuenetId, newsletterAccepted = null, createdBy = null) {
    const payload = {
      'valuenet_id': valuenetId,
      'newsletter_accepted': !!newsletterAccepted,
      'created_by': createdBy
    };
    return this.Made.request('rpc://utility/brevo_newsletter/update_contact', payload);
  }

  getNewslettertogoPreference(valuenetId) {
    return this.Made.request('rpc://utility/brevo_newsletter/is_subscribed', {
      'valuenet_id': valuenetId,
    });
  }

  sendBOFileRequestInitEmail(valuenet_id, bo_request_data, ticket_number) {
    return this.Made.request('rpc://utility/mail_controller/send_bo_file_request_init_email', {
      'valuenet_id': valuenet_id,
      'bo_request_data': bo_request_data,
      'ticket_number': ticket_number
    });
  }

  openNewsletterDialogIfUserChoiceIsMissing(valuenetId) {
    return this.Made.request('rpc://utility/brevo_newsletter/is_subscribed', {
      'valuenet_id': valuenetId,
    }).then(async isSubscribed => {
      const isImpersonated = this.Made.user.originalId;
      if (!isSubscribed) {
        const dialogRef = this.MatDialogService.open(NewsletterDialogComponent, {
          disableClose: true,
          data: {
            showCloseButton: isImpersonated
          },
          width: 1200
        });
        let createdBy = valuenetId;
        if (isImpersonated) {
          createdBy = await this.userService.getEmployeeIdByUserId(this.Made.user.originalId);
        }

        dialogRef.afterClosed().subscribe(result => {
          if (result === null) {
            return;
          }
          this.updateContact(
            valuenetId,
            result === "yes",
            createdBy
          )
        })
      }
    });
  }
}
mailService.$inject = $inject;
