//TODO: refactor
import './components';
import './branding.provider';
import './feedback.service';
import './quiz.factory';

// import './cache';
import './vbm';
import './vcp';

import menuService from './menuService';
import authenticationService from './authenticationService';
import customerService from './customerService';
import employeeService from './employeeService';
import paymentService from './paymentService';
import projectService from './projectService';
import userService from './userService';
import advisorService from './advisorService';
import mailService from './mailService';
import ShopService from './ShopService';
import StatisticService from './StatisticService';
import MonthsService from './MonthsService';
import PlausibilityService from './PlausibilityService';
import UserNotificationService from './UserNotificationService';
import potentialCalculatorService from './potentialCalculatorService';
import filmService from './filmService';
import dialogService from './dialogService';
import fileService from './fileService';
import TimetrackingService from './TimetrackingService';
import AppCacheService from './AppCacheService';
import BonusService from './BonusService';
import BonusCalculationService from './BonusCalculationService';
import mppService from './mppService';
import cmsService from './cmsService';
import DatevService from './datev/DatevService';
import bikeleasingService from './bikeleasingService';
import DatevCalculatorService from './datev/DatevCalculatorService';
import BenefitBonagoService from '../modules/benefit/bonago/benefit_bonago.service';
import MapsService from './mapsService';

import {downgradeInjectable} from "@angular/upgrade/static";
import debounceFactory from './debounceFactory';
import BenefitAccountService from './benefitAccountService';
import AdministrationService from './administrationService';
import yearlyService from './yearlyService';
import UserGroupsService from './UserGroupsService';
import FilenameService from './FilenameService';
import ACLService from './ACLService';
import SupportService from './SupportService';
import {ShopApiService} from './backend/shop-api.service';
import {BasketRepositoryService} from './backend/basket-repository.service';
import NeoComponentsService from './neo/NeoComponentsService';
import UtilityService from './UtilityService';
import CommonService from './CommonService';
import DigitalSigningService from './digital-signing/digital-signing.service';
import UserlaneService from './UserlaneService';
import {BackendFileUploadMapper} from "../../app/back-office/mappers/backend-file-upload-mapper.service";
import {CarLeasingApiService} from "../../app/car-leasing/services/car-leasing-api.service";
import {BenefitsBonagoController} from "../../app/direct-bonus/services/benefits-bonago.controller";
import {CarLeasingCatalogService} from "../../app/car-leasing/services/car-leasing-catalog.service";
import {DirectBonusService} from  "../../app/direct-bonus/services/direct-bonus.service";
import ByteplantService from './ByteplantService';
import ArbeitnehmerSpec from "./schema/arbeitnehmer.spec";
import ArbeitnehmerVCPSpec from "./schema/arbeitnehmerVCP.spec";
import SchemaService from "./schema/schema.service";
import SpecsService from "./schema/specs.service";
import CarAdvantageFuelMapper from "../../app/car-leasing/services/mappers/car-advantage-fuel-mapper.service";
import {PopupService} from  "../../app/shared/services/popup.service.ts";
import {DticketOrderService} from "../../app/dticket-order/services/dticket-order.service";
import {NewsletterDialogComponent} from "../../app/shared/components/newsletter-dialog/newsletter-dialog.component";


angular
  .module('VSPApp')
  .service('menuService', menuService)
  .service('authenticationService', authenticationService)
  .service('customerService', customerService)
  .service('employeeService', employeeService)
  .service('paymentService', paymentService)
  .service('projectService', projectService)
  .service('userService', userService)
  .service('advisorService', advisorService)
  .service('mailService', mailService)
  .service('ShopService', ShopService)
  .service('shopApiService', ShopApiService)
  .service('basketRepositoryService', BasketRepositoryService)
  .service('StatisticService', StatisticService)
  .service('MonthsService', MonthsService)
  .service('PlausibilityService', PlausibilityService)
  .service('UserNotificationService', UserNotificationService)
  .service('potentialCalculatorService', potentialCalculatorService)
  .service('filmService', filmService)
  .service('dialogService', dialogService)
  .service('fileService', fileService)
  .service('TimetrackingService', TimetrackingService)
  .service('AppCacheService', AppCacheService)
  .service('BonusService', BonusService)
  .service('BonusCalculationService', BonusCalculationService)
  .service('mppService', mppService)
  .service('cmsService', cmsService)
  .service('benefitAccountService', BenefitAccountService)
  .service('datevCalculatorService', DatevCalculatorService)
  .service('datevService', DatevService)
  .service('bikeleasingService', bikeleasingService)
  .service('administrationService', AdministrationService)
  .service('yearlyService', yearlyService)
  .service('UserGroupsService', UserGroupsService)
  .service('BenefitBonagoService', BenefitBonagoService)
  .service('BenefitBonagoService', BenefitBonagoService)
  .service('FilenameService', FilenameService)
  .service('ACLService', ACLService)
  .service('SupportService', SupportService)
  .service('MapsService', MapsService)
  .service('NeoComponentsService', NeoComponentsService)
  .service('UtilityService', UtilityService)
  .service('CommonService', CommonService)
  .service('DigitalSigningService', DigitalSigningService)
  .service('UserlaneService', UserlaneService)
  .service('ByteplantService', ByteplantService)
  .service('ArbeitnehmerSpec', ArbeitnehmerSpec)
  .service('ArbeitnehmerVCPSpec', ArbeitnehmerVCPSpec)
  .service('SchemaService', SchemaService)
  .service('SpecsService', SpecsService)
  .factory('debounceFactory', debounceFactory)
  .factory('CarLeasingApiService', downgradeInjectable(CarLeasingApiService))
  .factory('BenefitsBonagoController', downgradeInjectable(BenefitsBonagoController))
  .factory('CarLeasingCatalogService', downgradeInjectable(CarLeasingCatalogService))
  .factory('BackendFileUploadMapper', downgradeInjectable(BackendFileUploadMapper))
  .factory('DirectBonusService', downgradeInjectable(DirectBonusService))
  .factory('CarAdvantageFuelMapper', downgradeInjectable(CarAdvantageFuelMapper))
  .factory('PopupService', downgradeInjectable(PopupService))
  .factory('DticketOrderService', downgradeInjectable(DticketOrderService))
;
