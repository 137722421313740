<mat-dialog-content>
  <p>Lieber Mitarbeiter,</p>
  <p>
    wir möchten sicherstellen, dass Sie alle relevanten Informationen rund um die Benefits Ihres Arbeitgebers und das
    ValueNet Portal erhalten. Bisher haben Sie dazu noch keine Auswahl getroffen – das können Sie jetzt nachholen.
  </p>
  <mat-radio-group [(ngModel)]="selectedOption">
    <mat-radio-button value="yes">
      Ja, bitte! Ich möchte nichts verpassen und regelmäßig über meine Vorteile und das ValueNet Portal informiert
      werden.
    </mat-radio-button>
    <br/>
    <mat-radio-button value="no">
      Nein, danke. Ich möchte keine Informationen erhalten.
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<button mat-icon-button mat-dialog-close class="close-btn" (click)="closeWithoutSelection()" *ngIf="showCloseButton">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="closeDialog()">Bestätigen</button>
</mat-dialog-actions>
