import {Inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureYouSignServiceV3 {

  private madeRpcMethod: string = "rpc://utility/digitalsigniture/v3/yousign";

  constructor(
    @Inject('Made') private made: any,
  ) { }

  _makeCall(endpoint: string, data: object|null=null) {
    if (data)
      return this.made.request(this.madeRpcMethod + endpoint, data);
    else
      return this.made.request(this.madeRpcMethod + endpoint);
  }

  initiate(employeeId: number, documentUrl: string|null, resourceId: string, signatureType: string) {
    return this._makeCall('/initiate_signature_request', {
      employee_id: employeeId,
      document_url: documentUrl,
      resource_id: resourceId,
      signature_type: signatureType
    });
  }
}
