import {
  EMPLOYEE_STATUSES,
  NAME_EMPLOYEE_STATUSES_MAP,
  ID_EMPLOYEE_STATUSES_MAP,
  ID_EMPLOYEE_STATUS_FLOWS
} from '../../../services/employeeService';

import {
  NEO_COMPONENTS_WITH_DOCUMENTS, PROJECT_TAGS
} from '../../../services/customerService';

import {
  DEFAULT_YEARLY_DOCUMENTS_TAG_STRING
} from '../../../services/yearlyService';

import bonus_dialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/bonus/bonus_dialog.pug'
import filteredUsersDialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/filteredUsersDialog.pug'
import changeEmployeeStatusDialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/changeEmployeeStatusDialog.pug'
import userGroupsAdministrationDialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/userGroupsAdministrationDialog.pug'
import {BONUS_PROJECT_TYPES_BY_NAME} from "../../../services/bonus/bonus_project_types";
import {BONUS_PROJECT_STATES_BY_NAME} from "../../../services/bonus/bonus_project_states";
import {BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";
import {
  CustomerBulkAddUsersDialogComponent
} from "../../../../app/direct-bonus/components/customer/customer-bulk-add-users-dialog/customer-bulk-add-users-dialog.component";
import {ProjectActionsDialogData} from "../../../../app/direct-bonus/models/direct-bonus-table-dialog-data.model";
import {tryCatch} from "rxjs/internal-compatibility";


const DEFAULT_FILTERS = {
  id: '',
  custId: '',
  name: '',
  personal_number: '',
  hide_testclients: 0,
  made_status: {
    selected: [],
    options: [{
      name: 'Aktiv',
      id: 'active'
    }, {
      name: 'Eingeladen',
      id: 'yellow'
    }, {
      name: 'Deaktiviert',
      id: 'deactivated'
    }, {
      name: 'Inaktiv ohne @',
      id: 'red'
    }, {
      name: 'inaktiv mit @',
      id: 'blue'
    }, {
      name: 'Bikeleasing',
      id: 'bikeleasing'
    }, {
      name: 'PC-Leasing',
      id: 'pc-leasing'
    }]
  },
  neo_status: {
    selected: [],
    options: EMPLOYEE_STATUSES
  },
  last_login: {
    selected: [],
    options: [{
      display: 'innerhalb des letzten Monats',
      name: '0_month'
    }, {
      display: 'vor 1 - 3 Monaten',
      name: '1_month'
    }, {
      display: 'vor 3 - 6 Monaten',
      name: '3_month'
    }, {
      display: 'vor mehr als 6 Monaten',
      name: '6_month'
    }]
  }
};
const $inject = [
  '$q',
  '$location',
  '$scope',
  '$transitions',
  '$state',
  '$window',
  'DocumentConstants',
  'Made',
  'dialogService',
  'authenticationService',
  'customerService',
  'employeeService',
  'userService',
  'vbmData',
  'vbmService',
  'NotificationService',
  'moment',
  'BonusService',
  '$timeout',
  'UserGroupsService',
  'fileService',
  'lodash',
  'ACLService',
  'MatDialogService'
];
export default class CockpitEmployeeAdministrationController {

  constructor(
    $q,
    $location,
    $scope,
    $transitions,
    $state,
    $window,
    DocumentConstants,
    Made,
    dialogService,
    authenticationService,
    customerService,
    employeeService,
    userService,
    vbmData,
    vbmService,
    NotificationService,
    moment,
    BonusService,
    $timeout,
    UserGroupsService,
    fileService,
    lodash,
    ACLService,
    MatDialogService
  ) {

    this.$q = $q;
    this.$location = $location;
    this.$transitions = $transitions;
    this.$window = $window;
    this.$scope = $scope;
    this.DocumentConstants = DocumentConstants;
    this.Made = Made;
    this.dialogService = dialogService;
    this.authenticationService = authenticationService;
    this.customerService = customerService;
    this.employeeService = employeeService;
    this.userService = userService;
    this.vbmData = vbmData;
    this.NotificationService = NotificationService;
    this.moment = moment;
    this.BonusService = BonusService;
    this.$timeout = $timeout;
    this.UserGroupsService = UserGroupsService;
    this.fileService = fileService;
    this.vbmService = vbmService;
    this.lodash = lodash;
    this.ACLService = ACLService;
    this.MatDialogService = MatDialogService;

    this.generatingFile = false;

    this.customerId = $state.params.customerId;
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmDirectBonus = authenticationService.iAm('employer_directbonus');

    this.loading = true;
    this.ACLService.amIEmployer(this.customerId)
      .then((res) => {
        this.iAmEmployer = res;
      })
      .catch((err) => {
        console.err(err);
        this.iAmEmployer = false;
      })
      .finally(() => {
        this.loading = false;
      });


    this.hasDirectBonusAccess = this.iAmSpecialAdvisor || this.iAmDirectBonus;
    this.directBonusProject = {
      has_active: false,
      id: null
    };
    this.employeesWithBikeleasing = {};
    this.employeesWithPCLeasings = {};
    customerService
      .get(this.customerId)
      .then(customer => this.customer = customer);

    customerService
      .getConfiguration(this.customerId)
      .then(customerConfig => this.customerConfig = customerConfig);

    this.$scope.filters = angular.copy(DEFAULT_FILTERS);

    const search = $location.search();
    if (search && search.employeeId) {
      this.$scope.filters.id = search.employeeId;
      this.filterEmployees();
    } else {
      this.loadEmployees();
    }

    this.project_tags = PROJECT_TAGS;

    this.components = NEO_COMPONENTS_WITH_DOCUMENTS.map((component) => {
      return {
        name: component.display,
        value: component.id
      };
    });

    this.getUserGroups();

    this.getACLUserGroups();


  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  areFiltersEmpty(filters) {
    let user_entered_filter_keys = ['id', 'personal_number', 'name', 'made_status.selected', 'neo_status.selected', 'last_login.selected', 'order'];
    let are_filters_empty = true;

    user_entered_filter_keys.forEach((key) => {
      if ((this.lodash.get(filters, key, '').length > 0)) {
        are_filters_empty = false;
      }
    });

    return are_filters_empty;
  }

  async filterEmployees() {
    this.$timeout(() => {
      this.loading = true;
      this.filtered_employees = [];
    });

    await this.loadEmployees();

    if (!this.areFiltersEmpty(this.$scope.filters)) {


      if (this.employees) {
        this.filtered_employees = this.employees.filter((employee) => {
          let is_match = true;

          //made status checks
          if (this.$scope.filters.made_status.selected && this.$scope.filters.made_status.selected.length > 0) {
            let is_match_made_status = false;
            this.$scope.filters.made_status.selected.forEach((selected_status) => {
              if (selected_status.id === 'bikeleasing') {
                is_match_made_status = !!this.employeesWithBikeleasing[employee.id];
              } else if (selected_status.id === 'pc-leasing') {
                is_match_made_status = this.hasPCLeasing(employee.id);
              } else if (this.getMadeStatus(employee.id) === selected_status.id) {
                is_match_made_status = true;
              }
            });
            is_match = (is_match && is_match_made_status);
          }

          //neo status checks
          if (this.$scope.filters.neo_status.selected && this.$scope.filters.neo_status.selected.length > 0) {
            let is_match_neo_status = false;
            this.$scope.filters.neo_status.selected.forEach((selected_status) => {
              if (this.accessList[employee.id] && this.accessList[employee.id].neoStatus.name === selected_status.name) {
                is_match_neo_status = true;
              }
            });
            is_match = (is_match && is_match_neo_status);
          }

          //last login checks
          if (this.$scope.filters.last_login.selected && this.$scope.filters.last_login.selected.length > 0) {
            let is_match_last_login = false;
            if (this.accessList[employee.id] && this.accessList[employee.id].lastLogin !== null) {

              let last_login_date = new Date(this.accessList[employee.id].lastLogin * 1000);
              let today = new Date();
              let difference = this.getNumberOfMonthsBetweenDates(today, last_login_date);

              this.$scope.filters.last_login.selected.forEach((last_login) => {
                if (last_login.name === '0_month' && (difference < 1)) {
                  is_match_last_login = true;
                } else if (last_login.name === '1_month' && (difference >= 1 && difference < 3)) {
                  is_match_last_login = true;
                } else if (last_login.name === '3_month' && (difference >= 3 || difference < 6)) {
                  is_match_last_login = true;
                } else if (last_login.name === '6_month' && (difference >= 6)) {
                  is_match_last_login = true;
                }
              });

            }
            is_match = (is_match && is_match_last_login);
          }

          // checks for names, and employee_ids and personal_number and custId
          //check for name
          if (is_match && this.$scope.filters.name.length > 1) {
            let fullname = employee.vorname.toLowerCase() + ' ' + employee.name.toLowerCase();
            if (fullname.indexOf(this.$scope.filters.name.toLowerCase()) < 0) {
              is_match = false;
            }
          }

          if (is_match && ((this.$scope.filters.id.length === 1 && this.$scope.filters.id === '*') || (this.$scope.filters.id.length > 1 && employee.id.toString().indexOf(this.$scope.filters.id) > -1))) {
            is_match = true;
          } else if (this.$scope.filters.id.length > 1 && employee.id && employee.id.toString().indexOf(this.$scope.filters.id) === -1) {
            is_match = false;
          }

          if (is_match && this.$scope.filters.personal_number.length > 1) {
            if (!employee.personalnummer) {
              is_match = false;
            } else if (employee.personalnummer.indexOf(this.$scope.filters.personal_number) < 0) {
              is_match = false;
            }
          }

          if (is_match && this.$scope.filters.custId.length > 1) {
            if (!employee.custId) {
              is_match = false;
            } else if (employee.custId.indexOf(this.$scope.filters.custId) < 0) {
              is_match = false;
            }
          }
          // if we want to hide the test clients ( so we use the testclient filter) -> we get only those employees who are not testclient
          if (is_match && this.$scope.filters.hide_testclients) {
            is_match = !employee.testclient;
          }

          return is_match;
        });
      }
    } else {
      this.filtered_employees = [];
    }

    this.$timeout(() => {
      this.loading = false;
    });
  }

  getNumberOfMonthsBetweenDates(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4);
    return Math.abs(Math.round(diff));
  }

  initDocumentTagsConfig() {
    return {
      project_tag_selected: this.project_tags[0].value,
      component_tag_selected: undefined
    };
  }

  initNeoComponentCategories() {
    let categories = {};

    NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
      categories[component.id] = {
        title: component.display,
        documents: []
      };
    });

    return categories;
  }

  hasDirectBonusProjectActivated() {
    // TODO: check if there is a bonus project and if it in active state

    return this.hasDirectBonusAccess && this.directBonusProject.has_active;
  }

  openDirectBonusDialog(employeeData, event) {
    event.preventDefault();
    event.stopPropagation();

    this.dialogService.ngDialog.open({
      template: bonus_dialog_html(),
      plain: true,
      controller: 'BonusDialogController',
      controllerAs: '$ctrl',
      data: {
        customer_id: this.customerId,
        bonus_project_id: this.directBonusProject.id,
        employee_ids: employeeData ? [employeeData.id] : undefined
      },
      width: 800,
      closeByDocument: false,
      closeByEscape: false,
      closeByNavigation: true
    });
  }

  async generateCSV() {

    try {

      const usersWithActivationCodes = await this.customerService.getUsersWithActivationCodeForCustomer(this.customerId);

      if (!usersWithActivationCodes.length) {
        this.NotificationService.message('No users for CSV.')
        return;
      }

      const r = await this.dialogService.ngDialog.openConfirm({
        template: filteredUsersDialog_html(),
        plain: true,
        data: usersWithActivationCodes,
        controller: 'FilteredUsersDialogController',
        controllerAs: 'FilteredUsersDialogCtrl',
        width: 800,
        closeByDocument: false,
        closeByEscape: false,
        closeByNavigation: true
      });

    } catch (err) {
      console.error(err);
    }

  }

  async generateCustomerLocationsCSV() {
    try {
      const customerLocations = await this.customerService.getCustomerLocations(this.customerId);
      this.fileService.saveFile(
        customerLocations,
        ['id', 'name', 'strasse', 'plz', 'ort'],
        'customer_locations.csv'
      );
      console.log(this.customerId);
      console.log(customerLocations);
    } catch (err) {
      console.error(err);
    }
  }

  async loadEmployees() {
    // ensure we don't do multiple requests until the previous one is ready
    if (this.employeesPromise) {
      return this.employeesPromise;
    }

    let p = [];
    this.employees = [];
    p.push(
      this.customerService
        .getEmployees(this.customerId, Date.now(), true)
        .then(employees => {
          this.employees = employees;
        })
    );

    p.push(
      this.customerService
        .getEmployeesWithBikeleasing(this.customerId)
        .then(result => {
          this.employeesWithBikeleasing = result;
        })
    );

    p.push(
      this.customerService
        .getEmployeesWithPCLeasing(this.customerId)
        .then(result => {
          this.employeesWithPCLeasings = result;
        })
    );

    if (this.iAmAdvisor || this.iAmGAdvisor || this.iAmEmployer) {
      p.push(
        this.userService
          .getAccessStatusByCustomerId(this.customerId)
          .then(accessList => {
            this.set_all_to_korrektur = false;
            angular.forEach(accessList, (v, k) => {
              accessList[k].neoStatus = this.getNeoStatusForEmployee(v.neo_status);
              if (this.set_all_to_korrektur === false && accessList[k].neoStatus && accessList[k].neoStatus.name && (accessList[k].neoStatus.name === 'aktiv' || accessList[k].neoStatus.name === 'ruhend' || accessList[k].neoStatus.name === 'nachforschung')) {
                this.set_all_to_korrektur = true;
              }
            });
            this.accessList = accessList;
          })
          .catch(() => true)
      );
    }

    if (this.hasDirectBonusAccess) {
      let bonus_project_promise = this.BonusService.getBonusProject(this.customerId).then(bonus_projects => {
        this.bonus_projects = bonus_projects;

        // check if there are active direct bonus projects

        for (let i = 0; i < this.bonus_projects.length; i++) {
          let bonus_project = this.bonus_projects[i];
          if (bonus_project.project_type === BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id'] && bonus_project.project_state === BONUS_PROJECT_STATES_BY_NAME['published']['id']) {
            this.directBonusProject.has_active = true;
            this.directBonusProject.id = bonus_project['_id'];
            break;
          }
        }
      });
      p.push(bonus_project_promise);
    }

    this.employeesPromise = this.$q.all(p).finally(()=> {
      delete this.employeesPromise
    });

    return this.employeesPromise;
  }

  arbeitnehmerAnlegen(employee) {
    this.dialogService
      .employeeCreate(employee ? employee.id : undefined,
        employee ? undefined : this.customer.id, employee ? this.needMade : true)
      .then(() => {
        this.loadEmployees();
      });
  }

  mitarbeiterImportieren() {
    console.log('mitarbeiterImportieren employer status: ', this.iAmEmployer)
    console.log('user groups: ', this.getUserGroups())
    this.MatDialogService.open(
      CustomerBulkAddUsersDialogComponent, {
      width: '700px',
      data: {
        customerId: this.customer.id
      }
    })
  }

  arbeitnehmerContactDetails(employee) {

    this.dialogService
      .employeeContactDetails(employee)
      .then(() => this.loadEmployees());
  }

  arbeitnehmerFreischalten(employee, force) {

    this.dialogService
      .employeeInvite(employee.id, force)
      .then(() => this.loadEmployees());
  }

  arbeitnehmerFixUser(employee) {

    this.$timeout(() => {
      this.loading = true;
    });
    this.customerService.fixUser(this.customerId, employee.id).then(
      () => {
        this.NotificationService.message('Der Mitarbeiter wurde erfolgreich freigeschaltet.');
      },
      () => {
        this.NotificationService.error('Leider konnte der Mitarbeiter nicht freigeschalten werden.');
      }).finally(() => {
        this.filterEmployees();
        this.loading = false;
    });
  }

  previewCalculation(employeeData, employee, toggleContentShowable) {

    if (toggleContentShowable) {
      return;
    }
    this.vbmData.prepare(employeeData.id);

    // this.loading          = true;
    employee.vbm = this.vbmData.employees[employeeData.id];
    employee.roleEmployer = false;
    employee.roleAdvisor = false;
    employee.roleCmsEditor = false;
    employee.error = false;
    employee.invitation_date = this.getInvitationDate(employeeData.id, employee);
    employee.last_login_date = this.getLastLoginDate(employeeData.id, employee);
    employee.documents_metadata = this.initDocumentTagsConfig();

    let promises = [];

    promises.push(this.vbmData
      .getResult(employeeData.id)
      .catch(() => employee.error = true));

    promises.push(this.userService
      .getMadeUserByValuenetId(employeeData.id, Date.now())
      .then((user) => {
        employee.email = user.emails[0];
        employee._id = user._id;
        employee.active = user.active;
        employee.created = user.created;
        employee.registration_type = user.selfregister === true ? 'Selbstregistrierung' : 'VN-Registrierung';
        employee.groups = user.groups;
        employee.is_invited_in_cidaas = !!user.invitation_id;
        employee.can_invite = user.email && user.invitation_id === '' && user.cidaas_sub === '';
        employee.can_reinvite = user.email && user.invitation_id !== '' && user.cidaas_sub !== '';
        employee.cidaas_sub = user.cidaas_sub;
      })
    );

    if (this.iAmSpecialAdvisor) {
      promises.push(
        this.getContracts(employeeData.id, employee, false)
      );
    }

    promises.push(
      this.vbmService.getLatestCalculation(employeeData.id, true).then(finalized_contract => {
        employee.finalized_contracts = finalized_contract;
      })
    );

    promises.push(
      this.BonusService.getBonusesForEmployeeAdministration({
        customer_id: this.customerId,
        employee_id: employeeData.id
      }).then(bonuses => {
        for (let [bonus_id, bonus] of bonuses.entries()) {
          let bonus_component_prop = this.BonusService.getBonusComponentName(bonus);
          bonus.component_name = bonus.component[bonus_component_prop].display.title;
        }
        employee.bonuses = bonuses;
      })
    );

    promises.push(
      this.ACLService.getEmployeeCustomerSpecificGroups(employeeData.id)
        .then((employee_specific_groups) => {
          employee.customer_specific_groups = employee_specific_groups;
        })
    );

    this.$q
      .all(promises)
      .finally(() => {
        this.$timeout(() => {
          this.loading = false;
        }, 0);
      });
  }

  getContracts(employee_id, employee, only_last) {
    return this.employeeService.getContracts(employee_id, only_last)
      .then((res) => {
        employee.contracts = this.filterFinalizableContracts(res);
        employee.contracts.forEach((contract) => {
          if (contract.finalisierungsdatum) {
            contract['parsedFinalisationDate'] = this.moment.unix(contract.finalisierungsdatum).format('DD.MM.YYYY');
          }
        });
      });
  }

  filterFinalizableContracts(contracts) {
    let last_id;
    let last_finalized_contract_id;
    let filtered_contracts = [];
    let finalized_contracts_count = 0;
    if (contracts && contracts.length) {
      contracts.forEach((contract) => {
        if (contract.final == 'finalized') {
          last_id = contract.ai_id;

          if (finalized_contracts_count < 5) {
            filtered_contracts.push(contract);
            finalized_contracts_count += 1;
          } else {
            filtered_contracts.shift();
            filtered_contracts.push(contract);
          }
        }
      });

      if (last_id !== undefined) {
        contracts.forEach((contract) => {
          if (contract.ai_id > last_id && contract.final != 'finalized') {
            filtered_contracts.push(contract);
          }
        });
      } else {
        return contracts;
      }

    }

    return filtered_contracts;
  }

  setEmployeeCockpitFiles(employee, files) {
    employee.neo_document_categories = this.initNeoComponentCategories();
    employee.hasUploadedNeoDocuments = false;
    employee.neoDocuments = files.filter((file) => {
      if (file.meta.tags && (file.meta.tags.indexOf('neo') !== -1 || file.meta.tags.indexOf('evg_final') !== -1) && (-1 == file.meta.tags.indexOf(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING)) && !this.fileService.isAuditTrail(file.meta.tags)) {
        let category_set = false;
        Object.keys(employee.neo_document_categories).forEach((category) => {
          if (file.meta.tags.includes(category)) {
            employee.neo_document_categories[category].documents.push(file);
            category_set = true;
            employee.hasUploadedNeoDocuments = true;
          }
        });
        if (category_set === false) {
          employee.neo_document_categories.other.documents.push(file);
          employee.hasUploadedNeoDocuments = true;
        }
      }
      return file;
    });
    employee.salaryDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('salaryStatement')) && !this.fileService.isAuditTrail(file.meta.tags));
    employee.bgmDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('bgmCertificate')) && !this.fileService.isAuditTrail(file.meta.tags));
    employee.calculationDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('calculation')) && !this.fileService.isAuditTrail(file.meta.tags));
  }


  upload(employee) {
    let tags = [employee.documents_metadata.project_tag_selected];
    if (employee.documents_metadata.project_tag_selected !== 'salaryStatement') {
      tags.push(employee.documents_metadata.component_tag_selected);
    }

    let promises = [];

    employee.uploading = true;

    for (var i = 0; i < employee.files.length; i++) {
      promises.push(this.Made.upload(employee.files[i], tags, employee._id));
    }

    this.$q
      .all(promises)
      .then(() => employee.files = [])
      .finally()
      .then(() => this.userService.getUploadedFiles(employee._id))
      .then(files => this.setEmployeeCockpitFiles(employee, files))
      .finally(() => employee.uploading = false);
  }


  async openMatchingYouSignAuditTrail(document) {
    let procedure_id_tag = await this.fileService.getProcedureIDFileTag(document);
    await this.fileService.openMatchingYouSignAuditTrail(procedure_id_tag, this.all_files);
  }

  become(employeeData) {

    this.loading = true;
    const context_path = '/cockpit/arbeitnehmerverwaltung/' + employeeData.custId + '?employeeId=' + employeeData.id;

    this.dialogService
      .question(`Achtung, der Nutzer wird über diesen Vorgang informiert.
        Wollen Sie wirklich in ${employeeData.name}, ${employeeData.vorname}
        (${employeeData.id}) schlüpfen?`)
      .then(() => this.userService.enterProxy(employeeData.id, context_path))
      .catch(() => this.loading = false);

  }

  inviteAll($event) {
    this.loadEmployees().then(employees => {
      $event.target.disabled = true;
      const employeeIds = this.employees
        .map(employee => employee.id)
        .filter(employeeId => this.isDisabled(employeeId));

      this.userService
        .inviteMany(employeeIds)
        .finally(() => {
          $event.target.disabled = false;
          this.NotificationService.message('Alle Einladungen an die neuen Mitarbeiter wurden erfolgreich versendet.');
          this.loadEmployees();
        });
    });
  }

  isDisabled(employee_id) {
    let isDisabled = !this.accessList[employee_id] || this.accessList[employee_id].active === false;
    return isDisabled
  }

  hasNotLoggedIn(employee_id) {
    return !this.isDisabled(employee_id) && (this.accessList[employee_id] && !this.accessList[employee_id].lastLogin);
  }

  hasLoggedIn(employee_id) {
    return !this.isDisabled(employee_id) && (this.accessList[employee_id] && this.accessList[employee_id].lastLogin);
  }

  invitationalStatus(employee_id) {
    if (this.accessList[employee_id]) {
      if (this.accessList[employee_id].invitation_id !== null) {
        return true;
      }
    }
    return false;
  }

  getInvitationDate(employee_id, employee) {
    this.userService.getLastInviteDate(employee_id)
      .then(last_invite_date => employee.invitation_date = last_invite_date ? this.moment.unix(last_invite_date).format('DD.MM.YYYY') : '-');
  }

  getLastLoginDate(employee_id, employee) {
    employee.last_login = this.accessList[employee_id].lastLogin ? this.moment.unix(this.accessList[employee_id].lastLogin).format('DD.MM.YYYY') : '-';
  }

  refusedToc(employee_id) {
    return this.accessList[employee_id] && (this.accessList[employee_id].toc != null && !this.accessList[employee_id].toc['0']);
  }

  getTocStatus(employee_id) {
    if (!this.accessList[employee_id].toc) {
      return 'Kein Status';
    }

    if (this.accessList[employee_id].toc['0']) {
      let date = new Date(this.accessList[employee_id].toc['0'] * 1000);
      return 'Akzeptiert - ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    } else if (this.accessList[employee_id].toc) {
      let date = new Date(this.accessList[employee_id].toc * 1000);
      return 'Abgelehnt - ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    }
  }

  employeeHasBikeleasing(id) {
    return !!this.employeesWithBikeleasing[id];
  }

  hasPCLeasing(employee_id) {
    return this.lodash.get(this.employeesWithPCLeasings, `${employee_id}.has_pc_components`, false);
  }

  getNeoStatusForEmployee(neo_status) {
    let result = { display: ' - ', reserve_1: neo_status };

    // sanity check
    if (typeof neo_status !== 'string') {
      return result;
    }

    let status_split = neo_status.split(' ');
    let status_name = status_split.shift();
    let status_date = status_split.shift();

    let multiname_status = (status_name || '').split('_');
    let is_korrectur = multiname_status.indexOf('korrektur') > -1;
    let is_kontrolle = multiname_status.indexOf('kontrolle') > -1;

    if (status_name) {
      if (is_kontrolle || is_korrectur) {
        result = { ...result, ...NAME_EMPLOYEE_STATUSES_MAP[status_name.toLowerCase()], ...{ old_date: status_date } };
      } else {
        result = { ...result, ...NAME_EMPLOYEE_STATUSES_MAP[status_name], ...{ old_date: status_date } };
      }
    }

    return result;
  }

  setAllToKorrektur() {
    return this.userService.setAllToKorrekturByCustomerId(this.customerId, this.set_all_to_korrektur)
      .then(() => this.loadEmployees());
  }

  isStatusFromKorrekturFlow(neo_status) {
    if (!!(neo_status && neo_status.id) && (ID_EMPLOYEE_STATUSES_MAP[neo_status.id].flows.indexOf(27) > -1)) {
      return true;
    }
    return false;
  }

  changeNeoStatus(employee_id, $event) {
    $event.stopPropagation();
    $event.preventDefault();

    // stop functionality
    // return;
    let employee = this.employees.find(empl => empl.id === employee_id);
    let employee_access = this.accessList[employee_id];
    this.dialogService.ngDialog.openConfirm({
      template: changeEmployeeStatusDialog_html(),
      plain: true,
      data: {
        employee: employee,
        employee_access: employee_access
      },
      controller: 'ChangeEmployeeStatusDialogController',
      controllerAs: 'ChangeEmployeeStatusDialogCtrl',
      width: 800,
      closeByDocument: false,
      closeByEscape: false,
      closeByNavigation: true
    }).then(() => this.loadEmployees());

  }


  parseNeoStatus(employee_id) {
    if (!this.accessList) return;

    if (this.accessList[employee_id] && this.accessList[employee_id].neo_satus) {

      switch (this.accessList[employee_id].neo_satus.split(' ')[0]) {
        case 'aktiv': {
          return '<span class="neo-status status-green">Aktiv</span>';
        }
        case 'ruhend': {
          return '<span class="neo-status status-yellow">Ausserhalb LFZ</span>';
        }
        case 'korrektur': {
          return '<span class="neo-status status-green">In Nachberatung</span>';
        }
        case 'nachforschung': {
          return '<span class="neo-status status-green">Anpassung</span>';
        }
        case 'inaktiv': {
          return '<span class="neo-status status-red">Inaktiv</span>';
        }
        case 'kontrolle_s': {
          return '<span class="neo-status status-black">Kontrolle Self</span>';
        }
        case 'kontrolle_b': {
          return '<span class="neo-status status-black">Kontrolle Berater</span>';
        }
        case 'kontrolle_t': {
          return '<span class="neo-status status-black">Dokumentenfluss</span>';
        }
      }
    }

    return null;
  }

  calculateStatusFlags(employee_id) {
    const statuses = {
      is_existing: false,
      has_email: false,
      is_invited: false,
      has_logged_in: false,
      is_active: false,
      has_cidaas_sub: false
    }

    statuses.is_existing = this.employees.filter(item => item.id === employee_id).length > 0;
    if (statuses.is_existing && this.accessList[employee_id]) {
      statuses.has_email = this.accessList[employee_id].emails && this.accessList[employee_id].emails.length > 0 && this.accessList[employee_id].emails[0] !== "";
      statuses.is_invited = this.accessList[employee_id].invitation_id !== null && this.accessList[employee_id].invitation_id !== "";
      statuses.has_logged_in = !!(this.accessList[employee_id].lastLogin && this.accessList[employee_id].lastLogin != null);
      statuses.is_active = this.accessList[employee_id].active;
      statuses.has_cidaas_sub = this.accessList[employee_id].cidaas_sub !== null && this.accessList[employee_id].cidaas_sub !== "";
    }

    return statuses;
  }

  parseStatus(employee_id) {
    const statusFlags = this.calculateStatusFlags(employee_id);

    // const is_existing = this.employees.filter(item => item.id === employee_id).length > 0;
    // // const has_email = !!(this.accessList[employee_id] && this.accessList[employee_id].emails && this.accessList[employee_id].emails.length > 0);
    // const has_email = is_existing && this.accessList[employee_id].emails && this.accessList[employee_id].emails.length > 0 && this.accessList[employee_id].emails[0] !== "";
    // // const is_invited = this.invitationalStatus(employee_id);
    // const is_invited = is_existing && this.accessList[employee_id].invitation_id !== null && this.accessList[employee_id].invitation_id !== "";
    // // const has_logged_in = this.accessList[employee_id] && this.accessList[employee_id].lastLogin != null;
    // const has_logged_in = is_existing && this.accessList[employee_id].lastLogin != null;
    // const is_active = hasExists && this.accessList[employee_id] && this.accessList[employee_id].active;
    // const has_cidaas_sub = this.accessList[employee_id] && this.accessList[employee_id].cidaas_sub != null;

    return this.userService.getUserStatusColor(
      statusFlags.is_existing, statusFlags.has_email, statusFlags.is_invited,
      statusFlags.has_logged_in, statusFlags.is_active, statusFlags.has_cidaas_sub
    );
  }

  getMadeStatus(employee_id) {
    const statusFlags = this.calculateStatusFlags(employee_id);
    const statusColors = this.parseStatus(employee_id);

    // The order of evaluation is important!
    let made_status = '';
    made_status = (statusFlags.is_invited || statusColors.yellow) ? 'yellow' : made_status;
    made_status = (statusColors.green) ? 'active' : made_status;
    made_status = (statusColors.blue) ? 'blue' : made_status;
    made_status = (statusColors.grey) ? 'grey' : made_status;
    made_status = (statusColors.red) ? 'red' : made_status;
    made_status = (statusColors.black) ? 'deactivated' : made_status;

    // if (hasExists && this.accessList[employee_id] && hasEmail && this.accessList[employee_id].active === true) {
    //   made_status = 'active';
    //
    // } else if (hasExists && this.accessList[employee_id] && hasEmail && this.invitationalStatus(employee_id) && (this.accessList[employee_id].active !== true && this.accessList[employee_id].active !== 0)) {
    //   made_status = 'invited';
    //
    // } else if (hasExists && this.accessList[employee_id] && this.accessList[employee_id].active === 0) {
    //   made_status = 'deactivated';
    //
    // } else if (hasExists && this.accessList[employee_id] && hasEmail && this.invitationalStatus(employee_id) === false && this.accessList[employee_id].active === false) {
    //   made_status = 'blue';
    //
    // } else if (hasExists && this.accessList[employee_id] && !hasEmail && this.accessList[employee_id].active === false) {
    //   made_status = 'red';
    // }

    return made_status;
  }

  deactivate(employeeId) {

    this.dialogService
      .question('Soll dieser Nutzer wirklich deaktiviert werden?')
      .then(() => {
        this.loading = true;
        this.userService
          .deactivate(employeeId)
          .then(() => this.loadEmployees());
      })
      .finally(() => this.loading = false);
  }

  fixate() {
    this.modulesWorking = true;
    this.customerService
      .setFixated(this.customerId, this.customerConfig, this.customerConfig.fixated)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => this.modulesWorking = false);
  }

  fixUser() {
    this.$timeout(() => {
      this.loading = true;
    });
    this.customerService.fixAllUsers(this.customerId).then(
      () => {
        this.NotificationService.message('Erstellung erfolgreich');

        this.loadEmployees();
      },
      () => {
        this.NotificationService.error('Es ist kein Standardprojekt vorhanden, bitte anlegen / umbenennen');
        this.loadEmployees();
      }).finally(() => {
        console.log("LOADING IN FINALLY")
        this.loading = false;
    });
  }

  deleteDocument(employee, document, fileName = null) {
    fileName = fileName || document.filename;

    this.dialogService
      .frageDialog(`Sie möchten das Dokument "${fileName}" wirklich löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          this.userService.deleteFiles([document._id]);

          this.userService.getUploadedFiles(employee._id).then(files => {
            this.setEmployeeCockpitFiles(employee, files);
          });
        },
        () => {
        }
      );
  }

  deleteAllDocuments(employee, documents) {
    this.dialogService
      .frageDialog(`Sie möchten ${documents.length} Dateien löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          let fileIds = [];
          for (var document of documents) {
            fileIds.push(document._id);
          }
          this.userService.deleteFiles(fileIds);

          this.userService.getUploadedFiles(employee._id).then(files => {
            this.setEmployeeCockpitFiles(employee, files);
          });
        },
        () => {
        }
      );
  }

  getCheckoutDocumentFilename(document) {
    //  | date:'dd.MM.yyyy_hh.mm'

    let createdDate = new Date(document.meta.created * 1000),
      day = (createdDate.getDate() >= 10 ? '' : '0') + createdDate.getDate(),
      month = (createdDate.getMonth() >= 9 ? '' : '0') + (createdDate.getMonth() + 1),
      year = createdDate.getFullYear(),
      hours = (createdDate.getHours() >= 10 ? '' : '0') + createdDate.getHours(),
      minutes = (createdDate.getMinutes() >= 10 ? '' : '0') + createdDate.getMinutes();
    return `${this.DocumentConstants[document.meta.tags[1]].name}_${day}.${month}.${year}_${hours}.${minutes}`;
  }

  getNeoDocumentFilename(document) {
    if (document.meta.tags.includes(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING)) {
      let filename = `jahresabfrage_${this.moment().year()}_${document.filename}`;
      return filename;
    }
    return document.filename;
  }

  updateContract(contract, employee, method) {
    if (this.iAmSpecialAdvisor) {
      if (method === 'finalise') {
        this.employeeService.finaliseContract(contract.ai_id, contract.calc_id, contract.vertragsabschluss, employee._id)
          .then((res) => {
            if (res) {
              contract.final = 'finalized';
            }
          });
      } else if (method === 'unfinalise') {
        this.employeeService.unfinaliseContract(contract.ai_id, contract.calc_id)
          .then((res) => {
            if (res) {
              contract.final = null;
            }
          });
      }
    }
  }

  finaliseContract(ai_id, calc_id, date, employee_id) {
    return this.employeeService.finaliseContract(ai_id, calc_id, date, employee_id);
  }

  unfinaliseContract(ai_id, calc_id) {
    return this.employeeService.unfinaliseContract(ai_id, calc_id);
  }

  markFinalisedContract(contract, employeeData, method) {
    if (!employeeData.contract_to_be_updated || (employeeData.contract_to_be_updated && employeeData.contract_to_be_updated.ai_id !== contract.ai_id)) {
      employeeData.contract_to_be_updated = angular.copy(contract);
      if (method === 'finalise') {
        employeeData.contract_to_be_updated.final = 'finalized';
      } else if (method === 'unfinalise') {
        employeeData.contract_to_be_updated.final = null;
      }
    } else {
      employeeData.contract_to_be_updated = null;
    }
  }

  applyContractFinalisation(employeeData, employee) {
    if (this.iAmSpecialAdvisor && employeeData.contract_to_be_updated) {
      if (employeeData.contract_to_be_updated.final === 'finalized') {
        this.finaliseContract(employeeData.contract_to_be_updated.ai_id, employeeData.contract_to_be_updated.calc_id, this.moment(), employeeData.id).then((res) => {
          this.employeeService.getContracts(employeeData.id).then((res) => {
            employee.contracts = this.filterFinalizableContracts(res);
          });
          employeeData.contract_to_be_updated = null;
        }).catch((err) => {

        });
      } else if (employeeData.contract_to_be_updated.final === null) {
        this.unfinaliseContract(employeeData.contract_to_be_updated.ai_id, employeeData.contract_to_be_updated.calc_id).then((res) => {
          this.employeeService.getContracts(employeeData.id).then((res) => {
            employee.contracts = this.filterFinalizableContracts(res);
          });
          employeeData.contract_to_be_updated = null;
        }).catch((err) => {

        });
      }
    }
  }

  showStatusLog(employee) {
    this.dialogService.openStatusLog(employee);
  }

  openGroupAdministrationDialog(event, employee_id, employee_data) {
    event.preventDefault();
    event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: userGroupsAdministrationDialog_html(),
      plain: true,
      controller: 'UserGroupsAdministrationDialogController',
      controllerAs: '$ctrl',
      data: {
        employee_id: employee_id,
        employee_data: employee_data
      },
      width: 800,
      closeByDocument: false,
      closeByEscape: false,
      closeByNavigation: true
    }).then(() => this.loadEmployees());
  }

  getUserGroups() {
    return this.UserGroupsService.getAllUserGroups().then(
      (res) => {
        this.USER_GROUPS_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      }).catch((err) => {
        console.error(err);

      });
  }



  getACLUserGroups() {
    return this.ACLService.getACLUserGroups({ to_include_approval: true })
      .then((customer_specific_groups) => {
        this.CUSTOMER_SPECIFIC_ROLES_BY_NAME = customer_specific_groups.reduce((acc, user) => {
          acc[user['name']] = user;
          return acc;
        }, {});
      });
  }

  getEmployeeCustomerSpecificGroups(employee_id) {
    return this.ACLService.getEmployeeCustomerSpecificGroups(employee_id)
      .then((customer_specific_groups) => {
        return customer_specific_groups;
      })
      .catch((err) => {
        console.error(err);
        return {};
      });
  }

  async createEmployeesWithSachbezugList(fileFormat) {
    this.$timeout(() => {
      this.generatingFile = true;
    });
    try {
      const employees = await this.customerService.createEmployeesList(this.customerId);
      if (employees.length > 0) {
        switch (fileFormat) {
          case 'csv':
            this.fileService.saveFile(
              employees,
              ['ValueNet-ID', 'Vorname', 'Nachname', 'Personalnummer', 'CustID', 'NEO', 'Bonus', 'Aktivierungscode', 'letzte Anmeldung'],
              'SachbezugUmstellungValueNet.csv'
            );
            break;
          case 'pdf':
            const pdfData = await this.customerService.generateEmployeesListPdf(employees, '24619');
            for (const path of pdfData) {
              this.$window.open(path, '_blank');
            }
        }
      } else {
        this.NotificationService.message('Es wurden keine relevanten Nutzer gefunden.');
      }
    } catch (err) {
      this.NotificationService.error('Beim Erzeugen ist ein Fehler aufgetreten - ' + fileFormat);
    }
    this.$timeout(() => {
      this.generatingFile = false;
    });
  }

  purgeCidaasUser(employeeId) {
    this.dialogService
      .question('Wollen Sie diesen Nutzer wirklich aus Cidaas löschen?')
      .then(() => {
        this.loading = true;
        this.userService
          .purgeCidaasUser(employeeId)
          .then(() => {
            this.NotificationService.message('Der Nutzer wurde erfolgreich aktiviert');
            this.loadEmployees();
          }).catch((err) => {
            this.NotificationService.error('Fehlgeschlagen');
        });
      })
      .finally(() => this.loading = false);
  }

  activateUser(employeeId) {
    this.dialogService
      .question('Are you sure you want to activate this user?')
      .then(() => {
        this.loading = true;
        this.userService.activateUser(employeeId)
          .then(() => {
            this.NotificationService.message('Der Nutzer wurde erfolgreich aktiviert');
            this.filterEmployees();
          }).catch(() => {
            this.NotificationService.error('Fehlgeschlagen');
        });
      })
      .finally(() => this.loading = false);
  }

  isTheUserInvitable(employeeData){
    let employee_access = this.accessList[employeeData.id];
    let have_sufficient_powers = this.iAmSpecialAdvisor || this.iAmGAdvisor || this.iAmEmployer || this.iAmAdvisor;
    let is_not_invited = employee_access.invitation_id == null || employee_access.invitation_id == '';
    return have_sufficient_powers && is_not_invited;
  }

  isTheUserReinvitable(employeeData){
    let employee_access = this.accessList[employeeData.id];
    let have_sufficient_powers = this.iAmSpecialAdvisor || this.iAmGAdvisor || this.iAmEmployer || this.iAmAdvisor;
    let is_invited = employee_access.invitation_id != null && employee_access.invitation_id != '';
    return have_sufficient_powers && is_invited && employee_access.lastLogin == null;
  }

}
CockpitEmployeeAdministrationController.$inject = $inject;
