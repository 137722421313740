/**
 * #COMMENT_LEX: There is also a ShopService already. So the functionality should be merged with this one. Or this
 * should be split up into a BasketApiService, PcComponentService,... etc. Just creating it separately here for
 * illustration purposes. It basically creates the api requests that I removed from the model classes.
 *
 * It's important that the Api service classes are the only ones having a Made dependency.
 */
import moment from 'moment';
import {BasketArticle} from '../../models/basket-article';
import {Basket} from '../../models/basket';
import {BasketItem} from '../../models/basket-item';
import {BasketCalculation} from '../../models/basket-calculation';
import {CatalogArticle} from '../../models/catalog-article';
import {ArticleAvailability, ShippingInfo} from '../../models/article-availability';
const $inject = [
  'Made', 'VSP_CONSTANTS'
];
export class ShopApiService {

  constructor(Made, VSP_CONSTANTS) {
    this.Made = Made;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
  }

  /**
   * @param articleNo
   * @returns {Promise<null|CatalogArticle>}
   */
  async getCatalogArticle(articleNo) {
    let response = await this.Made.request('rpc://shop/mpp/getProduct', {
      'filter': articleNo,
    });
    if (!response) {
      return null;
    }
    let shippingInfo = response['Liefertermin'] === 'Verfügbar'? ShippingInfo.AVAILABLE : ShippingInfo.OTHER;
    let availability = new ArticleAvailability(response['Verfuegbarkeit'], shippingInfo);
    return new CatalogArticle(
      articleNo,
      response['Artikelname'],
      response['Preis_Brutto'],
      response['Calculated_Preis'],
      availability
    );
  }

  async getPcBasketLogs(basketId, reason_id=null, only_last=false) {
    return await this.Made.request('rpc://shop/mpp/get_basket_logs', {
      basket_id: basketId,
      reason_id: reason_id,
      only_last: only_last
    });
  }

  /**
   * @param {Basket} basket
   * @returns {Promise<Basket>}
   */
  async saveBasketItems(basket) {
    let openStates = basket.items.map(i => i.open);
    let savedBasket = await this._setBasketItems(basket.employee_id, basket.items);

    // #COMMENT_LEX: restore opened state. This does not belong here but into the sidebar. Open state should be
    // independent of the model classes. Only the sidebar knows which items are open or whether there is this
    // distinction in the first place (all of them could always be open).
    for (let i=0 ; i < openStates.length && i < savedBasket.items.length ; i++) {
      savedBasket.items[i].open = openStates[i];
    }

    return savedBasket;
  }

  async _setBasketItems(employee_id, items) {
    let response = await this.Made.request('rpc://vbm/neo/pc/setBasketItems', {
      'employee_id': employee_id,
      'basket_items': items.map(bi => ({
          'Artikelnummer': bi.article.articleNo,
          'netprice': bi.article.netPrice,
          'price': bi.article.grossPrice,
          'Artikelname': bi.article.name,
          'quantity': bi.quantity,
        })
      )
    });
    return ShopApiService.makeBasket(response.employee_id, response);
  }

  static makeBasket(employeeId, apiResponse) {
    let items = ShopApiService._makeBasketItems(apiResponse.articles);
    console.log("apiResponse.basket", apiResponse);
    let calculation = ShopApiService._makeBasketCalculationValuesFromBasketResponse(apiResponse);

    // #TODO_LEX: is this sometimes not set? Is it used at all?
    let contract_start_date = moment(apiResponse.beginDate);
    let contract_end_date = moment(apiResponse.endDate);
    let contract_preend_date = null;
    if (apiResponse.preend_information) {
      contract_preend_date = moment(apiResponse.preend_information.preend_date);
    }

    let handlingFeeBrutto = null;
    if (apiResponse.configuration) {
      handlingFeeBrutto = apiResponse.configuration['handling_fee_brutto'];
    }

    let orderProcess = apiResponse.order_process;
    const contractId = apiResponse.contract_id;
    const confirmation = apiResponse.confirmation;

    return new Basket(
      apiResponse._id,
      employeeId,
      items,
      calculation,
      contract_start_date,
      contract_end_date,
      contract_preend_date,
      apiResponse.preorder,
      apiResponse.closed,
      handlingFeeBrutto,
      orderProcess,
      contractId,
      confirmation,
    );
  }

  static _makeBasketItems(responseItems) {
    let result = [];
    for (let articleApiResponse of responseItems) {
      result.push(new BasketItem(
        new BasketArticle(
          articleApiResponse.Artikelnummer,
          articleApiResponse.Artikelname,
          articleApiResponse.price,
          articleApiResponse.netprice,
        ),
        articleApiResponse.quantity
      ));
    }
    return result;
  }

  static _makeBasketCalculationValuesFromBasketResponse(response) {
    // #TODO_LEX: What happens if some of the values are not set on the apiResponse? In the old code that case was
    // handled. Does it happen?
    return new BasketCalculation(
      response.leasing,
      response.cart_leasing_month_brutto,
      response.cart_leasing_month_netto,
      response.sum,
      response.leasing,
      response.brutto_base,
      response.brutto_sum,
      response.netto_sum
    );
  }

  async getBasketCalculationValues(basket) {
    let apiResponse = await this.Made.request('rpc://shop/mpp/get_basket_calculation_values', {
      basket: basket,
      with_basket_validations: true,
      with_article_validations: true
    });
    return ShopApiService._makeBasketCalculationValuesFromBasketResponse(apiResponse);
  }

}
ShopApiService.$inject = $inject;
