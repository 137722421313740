import {CASHBACK_COMPONENT_NAMES, DEFAULT_BONUS_PROJECT_HEADLINE} from '../../../services/BonusService';
import {CHECKOUT_TYPES_BY_NAME} from "../../../services/bonus/checkout_types";

const $inject = [
  '$scope',
  '$state',
  'BonusService',
  'authenticationService',
  'customerService',
  'Made',
  '$q',
  'vbmData',
  'lodash',
  '$timeout',
  'dialogService',
  'moment'
];

export class BonusDashboardController {
  constructor(
    $scope,
    $state,
    BonusService,
    authenticationService,
    customerService,
    Made,
    $q,
    vbmData,
    lodash,
    $timeout,
    dialogService,
    moment
  ) {
    Object.assign(this, {
      $scope,
      BonusService,
      $state,
      authenticationService,
      customerService,
      Made,
      $q,
      vbmData,
      lodash,
      $timeout,
      dialogService,
      moment
    });

    this.loading = {
      components: true
    };
    this.DEFAULT_BONUS_PROJECT_HEADLINE = DEFAULT_BONUS_PROJECT_HEADLINE;

    this.initBonus()
      .finally(() => {
        this.$timeout(() => {
          this.loading.components = false;
        }, 0);
      });

  }

  transition(component_name, bonus_id) {
    this.$state.go(`inApp.bonus.dashboard.module${component_name}`, {
      bonusProjectId: this.bonus_project_id,
      bonusProject: this.bonus_project,
      bonusId: bonus_id,
      bonuses: this.bonuses,
      bonusesById: this.bonuses_by_id
    });
  }

  isComponentSelected(component) {
    let is_component_selected = false;
    for (let checkout_type of [CHECKOUT_TYPES_BY_NAME['current'], CHECKOUT_TYPES_BY_NAME['future']]){
      let bonus_component_config_key = `bonus.${this.bonus_project_id}.${checkout_type.key}.${component.name}`;
      let bonus_component_config = this.lodash.get(this.employee, bonus_component_config_key);
      is_component_selected = is_component_selected || (!!bonus_component_config && !this.isComponentUsed(bonus_component_config));
    }

    return is_component_selected
  }

  isComponentCheckoutAllowed(bonus_id) {
    let bonus = this.bonuses_by_id[bonus_id];
    let component_name = this.BonusService.getBonusComponentName(bonus);

    let is_component_cashback = CASHBACK_COMPONENT_NAMES.includes(component_name);

    if (is_component_cashback) {
      return this.is_cashback_request_available;
    }
    return this.BonusService.isComponentCheckoutAllowed(bonus);
  }

  isBonusDefaultStartDateCheckoutAvailable(bonus_id) {
    let bonus = this.bonuses_by_id[bonus_id];
    let component_name = this.BonusService.getBonusComponentName(bonus);
    let is_component_cashback = CASHBACK_COMPONENT_NAMES.includes(component_name);

    if (is_component_cashback) {
      return this.is_cashback_request_available;
    }

    return this.BonusService.isBonusDefaultStartDateCheckoutAvailable(bonus);
  }

  isComponentActive(display_component_config) {
    if (!this.bonuses_by_id) {
      return false;
    }

    const bonus = this.bonuses_by_id[display_component_config['bonus_id']];
    return this.BonusService.isComponentActive(bonus);
  }

  isComponentUsed(display_component_config) {
    return this.BonusService.isComponentUsed({ state: display_component_config.bonus_state });
  }

  isDecisionDateValid(bonus_id) {
    let bonus = this.bonuses_by_id[bonus_id];
    return this.BonusService.isDecisionDateValid(bonus);
  }

  componentTileClicked(component_display_config, $event) {
    if (this.isComponentUsed(component_display_config.name)) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  async initBonus() {
    this.iAmAdvisor = this.authenticationService.iAm('advisor');
    this.iAmGAdvisor = this.authenticationService.iAm('gadvisor');
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.customer_id = await this.customerService.getCustomerId();
    this.employee_id = this.Made.user.valuenet_id;

    this.bonus_project_id = this.$state.params.bonusProjectId;

    this.employee = this.vbmData.employees[this.employee_id];
    let promises = {};

    let watchers = [];

    // watch the custom_checkout_date to ensure correct bonuses are shown
    this.$scope.$watch(() => { return this.BonusService.custom_checkout_date; }, (newValue, oldValue) => {
      if (!angular.equals(newValue, oldValue)) {
        this.displayBonuses();
      }
    }, true);

    // 1. get selected bonus_project
    promises.bonus_project = this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id).then(bonus_project => {
      this.bonus_project = bonus_project;
    });

    // 2. get bonuses
    promises.bonuses = this.BonusService.getBonusesForEmployee({
      customer_id: this.customer_id,
      bonus_project_id: this.bonus_project_id,
      employee_id: this.employee_id,
      to_attach_checkout_validations: true
    }).then(bonuses => {
      this.bonuses = bonuses;
      this.bonuses_by_id = this.bonuses.reduce((acc, bonus) => {
        acc[bonus['_id']] = bonus;
        return acc;
      }, {});
    });

    // 3. get available components
    promises.available_components = this.BonusService.getAvailableBonusComponents(false)
      .then(components => {
        this.available_components = components;
        this.available_components_by_name = components.reduce((acc, component) => {
          acc[component.name] = component;
          return acc;
        }, {});
      });

    promises.is_cashback_available_promise = this.BonusService.isCashbackRequestAvailable(this.employee_id, this.bonus_project_id)
      .then(is_cashback_request_available => {
        this.is_cashback_request_available = is_cashback_request_available;
      }).catch(() => {
        this.is_cashback_request_available = false;
      });

    // initialize everything
    await this.$q.all(promises);

    this.displayBonuses();

  }

  displayBonuses() {
    // if there are no bonuses
    if (!this.bonuses) {
      this.bonus_components_for_display = [];
      return;
    }

    // make presentable
    let bonus_components_for_display = this.bonuses
      .filter((bonus) => {
        let component_name = this.BonusService.getBonusComponentName(bonus);
        let visibility_settings = this.lodash.get(bonus, `component.${component_name}.configuration.dashboard.visible`);
        if (!visibility_settings) {
          return true;
        } else {
          let start_date = this.moment(visibility_settings['start_date']);
          let end_date = this.moment(visibility_settings['end_date']);
          let now = this.moment();
          if (this.BonusService.custom_checkout_date) {
            now = this.moment(this.BonusService.custom_checkout_date);
          }
          return now.isBefore(end_date) && now.isAfter(start_date);
        }
      })
      .filter((bonus)=>{
        let is_valid_for_leader_checkout = this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_valid_for_leader_checkout');
        let is_leader = this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_leader');

        if (is_leader && !is_valid_for_leader_checkout) {
          return false;
        }

        return true;
      })
      .map((bonus_config) => {
        let component_config = this.available_components_by_name[this.BonusService.getBonusComponentName(bonus_config)];
        let component_id = component_config.id;

        return {
          title: component_config.display.title,
          // value: component.properties.value || '',
          bonus_id: bonus_config['_id'],
          bonus_state: bonus_config.state,
          name: component_config.name,
          id: component_id,
        };
      });
    this.bonus_components_for_display = this.lodash.sortBy(bonus_components_for_display, ['title', 'id']);
  }
}

BonusDashboardController.$inject = $inject;
